import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { BlockStack, Box, Card, InlineStack, Link, List, Modal, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import {
  aiScreeningInitialValues,
  getAiScreeningFields,
  normalScreeningFields,
  normalScreeningInitialValues,
  stageFields,
  stageInitialValues,
} from "@/Assets/Mocks/StageDetails.mock";
import { getTemplateFields, templateInitialValues } from "@/Assets/Mocks/Template.mock";
import { getSaveTemplateFields } from "@/Assets/Mocks/Template.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";

export function AddInterview(props) {
  const {
    actionType,
    showTemplateSaveButton,
    backUpStageValue,
    setBackUpStageValue,
    stageData,
    selectedStage,
    setShowAddInterview,
    status,
    jobData,
    childRef,
  } = props;
  const [templatemodelOpen, setTemplatemodelOpen] = useState(true);
  const [templateFormValues, setTemplateFormValues] = useState(templateInitialValues);
  const stageFormRef = useRef();
  const [stageFormValues, setStageFormValues] = useState(stageInitialValues);
  const screeningFormRef = useRef();
  const saveTemplateFormRef = useRef();
  const [screeningFormValues, setScreeningFormValues] = useState(normalScreeningInitialValues);
  const [normalScreeningFormFields, setNormalScreeningFormFields] = useState(normalScreeningFields);
  const [questionTypeLevel, setQuestionTypeLevel] = useState(false);
  const [assessmentLevel, setAssessmentLevel] = useState("low");
  const [screeningFormFields, setScreeningFormFields] = useState(normalScreeningFormFields);
  const [noQuestion, setNoQuestion] = useState(false);
  const [aiScreeningFormValues, setAiScreeningFormValues] = useState(aiScreeningInitialValues);
  const [normalScreeningFormValues, setNormalScreeningFormValues] = useState(normalScreeningInitialValues);
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);
  const [isSaveTemplateModal, setIsSaveTemplateModal] = useState(false);
  const [template, setTemplate] = useState(false);
  const [saveTemplateFields, setSaveTemplateFields] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [saveAllTemplateFields, setSaveAllTemplateFields] = useState([]);
  const [saveTemplateFormValues, setSaveTemplateFormValues] = useState(templateInitialValues);
  const [templateLable, setTemplateLable] = useState();

  const handleTemplateModalClose = () => {
    setTemplateFormValues(templateInitialValues);
    setShowAddInterview(false);
    setTemplatemodelOpen(false);
  };

  const saveStage = async (template = false) => {
    await stageFormRef.current.handleSubmit();
    await stageFormRef.current.validateForm();
    if (!noQuestion) {
      await screeningFormRef.current.handleSubmit();
      await screeningFormRef.current.validateForm();
    }

    handleSubmit();
  };

  const onStageFormChange = (value) => {
    setQuestionTypeLevel(value?.stagePreset === "noQuestion");
    setAssessmentLevel(value?.assessmentLevel);
    setBackUpStageValue((prev) => {
      return { ...prev, [stageFormValues.stagePreset]: screeningFormValues };
    });
    setNoQuestion(value?.stagePreset === "noQuestion" ? true : false);
    setStageFormValues((prev) => ({ ...prev, ...value }));
    const isValueChanged = value.stagePreset !== stageFormValues.stagePreset;
    const isAiScreening = value.stagePreset === "aiScreening";
    const isNormalScreening = value.stagePreset === "normalScreening";
    const isBothScreening = value.stagePreset === "bothScreening";

    if (isValueChanged) {
      if (isAiScreening) {
        setScreeningFormFields(getAiScreeningFields(false, value.assessmentType === "single"));
        setScreeningFormValues({ ...aiScreeningFormValues, ...backUpStageValue.aiScreening });
      } else if (isNormalScreening) {
        setScreeningFormFields(normalScreeningFields(value.assessmentType === "single"));
        setScreeningFormValues({ ...normalScreeningFormValues, ...backUpStageValue.normalScreening });
      } else if (isBothScreening) {
        setScreeningFormFields([
          ...getAiScreeningFields(true, value.assessmentType === "single"),
          ...normalScreeningFields(value.assessmentType === "single"),
        ]);
        setScreeningFormValues({
          ...aiScreeningFormValues,
          ...normalScreeningFormValues,
          ...backUpStageValue.bothScreening,
        });
      }
    } else {
      if (isAiScreening) {
        setScreeningFormFields(getAiScreeningFields(false, value.assessmentType === "single"));
      } else if (isNormalScreening) {
        setScreeningFormFields(normalScreeningFields(value.assessmentType === "single"));
      } else if (isBothScreening) {
        setScreeningFormFields([
          ...getAiScreeningFields(true, value.assessmentType === "single"),
          ...normalScreeningFields(value.assessmentType === "single"),
        ]);
      }
    }
  };

  const handleFormSubmit = useCallback((value) => {}, []);

  const onScreeningFormChange = (value) => {
    if (stageFormValues.stagePreset != "aiScreening") {
      value.questionList.map((item) => {
        if (
          item.answerType === "number" ||
          item.questionType === "video" ||
          item.answerType === "checkbox" ||
          item.answerType === "radio"
        ) {
          item["aiEvaluation"] = false;
        }
        return item;
      });
    }
    setScreeningFormValues((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    if (actionType !== "add") {
      onStageFormChange(stageData);
      setStageFormValues(stageData);
      setScreeningFormValues(stageData);
    }
  }, []);

  const handleSubmit = async () => {
    if (noQuestion) {
      const isStageValid = stageFormRef.current.isValid;
      if (!isStageValid) return;
      const { values: stageValues } = stageFormRef.current;
      const data = {
        stageDetails: {
          ...stageValues,
        },
        jobId: jobData?._id,
      };
      await fetch.post("/stage", { data: data });
      showToast(
        actionType === "add" ? "Interview Round Created successfully" : "Interview Round Updated successfully"
      );
    } else {
      const isStageValid = stageFormRef.current.isValid;
      const isScreeningValid = screeningFormRef.current.isValid;
      if (!isStageValid || !isScreeningValid) return;
      const { values: stageValues } = stageFormRef.current;
      const { values: screeningValues } = screeningFormRef.current;
      if (stageValues.stagePreset === "normalScreening" || stageValues.stagePreset === "bothScreening") {
        screeningValues.questionList.forEach((question) => {
          question.questionTime =
            !question?.questionTime || question.questionTime === "" ? 60 : Number(question.questionTime);
          question.answerTime =
            !question?.answerTime || question.answerTime === "" ? 300 : Number(question.answerTime);
        });
      }
      const data = {
        stageDetails: {
          ...stageValues,
          ...(stageValues.stagePreset === "aiScreening" || stageValues.stagePreset === "bothScreening"
            ? {
                totalQuestions: screeningValues.totalQuestions,
                aiAnswerType: screeningValues.aiAnswerType,
                answerTime: screeningValues?.answerTime ? Number(screeningValues?.answerTime) : 300,
                questionTime: screeningValues?.questionTime ? Number(screeningValues?.questionTime) : 60,
                aiQuestionPosition: screeningValues.aiQuestionPosition || "before",
              }
            : {}),
          ...(stageValues.stagePreset === "normalScreening" || stageValues.stagePreset === "bothScreening"
            ? { questionList: screeningValues.questionList }
            : {}),
        },
        jobId: jobData?._id,
        ...(actionType === "update" && selectedStage?._id ? { _id: selectedStage._id } : {}),
      };

      const url = template ? "/stageTemplate" : "/stage";
      if (template) {
        if (saveTemplateFormRef?.current?.values?.template == "none") {
          data["stageDetails"]["label"] = saveTemplateFormRef?.current?.values?.label
            ? saveTemplateFormRef?.current?.values?.label
            : templateLable;
          delete data?.jobId;
          delete data?._id;
        } else {
          data["stageDetails"]["label"] = templateLable;
          data["_id"] = saveTemplateFormRef?.current?.values?.template;
        }
      }

      await fetch.post(url, { data: data, stageData: stageData });

      if (template) {
        showToast(
          saveTemplateFormRef?.current?.values?.template == "none"
            ? "Interview Round Template Created successfully"
            : "Interview Round Template Updated successfully"
        );
        setIsSaveTemplateModal(false);
        getTemplates();
        setTemplate(false);
        setSaveTemplateFormValues(templateInitialValues);
      } else {
        showToast(
          actionType === "add" ? "Interview Round Created successfully" : "Interview Round Updated successfully"
        );
      }
    }
    if (status === "Template") {
      setShowAddInterview(false);
    }
    if (!template) {
      setTemplatemodelOpen(false);
      setShowAddInterview(false);
    }
    setNoQuestion(false);
    childRef.current.fetchData();
  };

  const saveTemplate = async (template = false) => {
    setTemplate(template);
    await stageFormRef.current.handleSubmit();
    await screeningFormRef.current.handleSubmit();
    await stageFormRef.current.validateForm();
    await screeningFormRef.current.validateForm();
    const isStageValid = stageFormRef.current.isValid;
    const isScreeningValid = screeningFormRef.current.isValid;
    if (!isStageValid || !isScreeningValid) return;
    setIsSaveTemplateModal(true);
  };

  const handleSaveTemplateCancle = () => {
    setTemplate(false);
    setIsSaveTemplateModal(false);
  };

  const onSaveTemplateFormChange = (value) => {
    if (value.template !== "none") {
      let template = saveTemplateFields.filter((item) => item.label !== "Template Title");
      let label = templates.filter((item) => item.value === value?.template)[0];
      setTemplateLable(label?.label);
      setSaveTemplateFields(template);
    } else {
      setSaveTemplateFields(saveAllTemplateFields);
    }
    setSaveTemplateFormValues((prev) => ({ ...prev, ...value }));
  };

  const getTemplates = useCallback(async () => {
    const response = await fetch.get("/stageTemplates");
    if (response?.data) {
      setTemplates(response.data);
      setSaveTemplateFields(getSaveTemplateFields(response.data));
      setSaveAllTemplateFields(getSaveTemplateFields(response.data));
    }
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      <BannerModal
        instant={true}
        open={templatemodelOpen}
        onClose={handleTemplateModalClose}
        title={actionType === "update" ? `Edit Interview ${status}` : `Add Interview ${status}`}
        primaryAction={
          status === "Template" && actionType === "add"
            ? undefined
            : {
                content: "Save",
                onAction: saveStage,
              }
        }
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleTemplateModalClose,
          },
          showTemplateSaveButton && !noQuestion
            ? {
                content: "Save template",
                onAction: () => saveTemplate(true),
              }
            : null,
        ].filter((item) => item !== null)}
        children={
          <BlockStack gap={400}>
            <CommonForm
              formRef={stageFormRef}
              initialValues={stageFormValues}
              formFields={stageFields(assessmentLevel, questionTypeLevel)}
              onFormChange={onStageFormChange}
              isSave={false}
              onSubmit={handleFormSubmit}
              noValueChanged={false}
              enableReinitialize={true}
            />
            {!noQuestion ? (
              <CommonForm
                formRef={screeningFormRef}
                initialValues={screeningFormValues}
                formFields={screeningFormFields}
                isSave={false}
                onFormChange={onScreeningFormChange}
                onSubmit={handleFormSubmit}
                noValueChanged={false}
                enableReinitialize={true}
              />
            ) : (
              <Card>
                <BlockStack gap={200}>
                  <Text variant="bodyLg" fontWeight="bold">
                    This will help when you want to take candidate normal information. don’t want to send any
                    assessment or question.{" "}
                  </Text>
                  <div className="instruction">
                    <List>
                      <List.Item>Create No Question Type.</List.Item>

                      <List.Item>Make it public and select information you want.</List.Item>
                      <List.Item>Add public link to your job post.</List.Item>
                      <List.Item>You will start getting responses automatically.</List.Item>
                    </List>
                  </div>
                </BlockStack>
              </Card>
            )}
          </BlockStack>
        }
        size={"large"}
      />
      <Modal
        open={isSaveTemplateModal}
        onClose={handleSaveTemplateCancle}
        title="Save Template"
        primaryAction={{
          content: "Save",
          onAction: saveStage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSaveTemplateCancle,
          },
        ]}
      >
        <Box padding="300">
          <CommonForm
            formRef={saveTemplateFormRef}
            onFormChange={onSaveTemplateFormChange}
            initialValues={saveTemplateFormValues}
            formFields={saveTemplateFields}
            isSave={false}
          />
        </Box>
      </Modal>
    </>
  );
}
