import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Banner, BlockStack, Card, Divider, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { RightIcon } from "@/Assets/Index";
import Review from "../../../Review/Review";
import InterviewAnswer from "../Common/InterviewAnswer";
import InterviewQuestion from "../Common/InterviewQuestion";
import QuestionSummary from "../Common/QuestionSummary";
import { DeactivePage } from "../Common/Steps/DeactivePage";
import { InitialInstruction } from "./InitialInstruction";

export const AssessmentTest = (props) => {
  const { isPublic, publicAssessmentData, organizationData, stage } = props;
  const fetch = useAuthenticatedFetch();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [assessmentData, setAssessmentData] = useState();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [isFeedback, setIsFeedback] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSummery, setShowSummary] = useState(true);
  const [countdown, setCountdown] = useState(2);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [bannerShow, setBannerShow] = useState(false);

  const handleNextQuestion = () => {
    if (currentQuestion?.currentQuationIndex < assessmentData?.questionsList.length - 1) {
      let crtQuation = assessmentData?.questionsList[currentQuestion?.currentQuationIndex + 1];
      let currentQuationIndex = currentQuestion?.currentQuationIndex + 1;
      setShowSummary(true);
      setCountdown(Number(crtQuation?.answerTime) * 60);
      setCurrentQuestion({
        crtQuation,
        currentQuationIndex: currentQuationIndex,
      });
    } else {
      setBannerShow(true);
    }
  };

  const fullScreenChange = async () => {
    if (assessmentData?._id) {
      await fetch.post(
        "/assessment",
        {
          fullScreenChange: true,
          assessment_id: assessmentData?._id,
        },
        false
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (screen.width === window.innerWidth) {
        setIsFullscreen(true);
      } else {
        fullScreenChange();
        setIsFullscreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isSubmitted && isStarted) {
        const message = "Leaving this page may result in loss of progress. Are you sure?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isStarted, isSubmitted]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let AssessmentData;
        if (isPublic && !publicAssessmentData) {
          const assessmentId = localStorage.getItem("assessmentId");
          const assessment = await fetch.get(`/getAssessment/${assessmentId}`);
          AssessmentData = assessment?.data;
          let crtQuation = AssessmentData.questionsList[AssessmentData.answers?.length];

          let currentQuationIndex = AssessmentData.answers?.length;

          setCurrentQuestion({ crtQuation, currentQuationIndex: currentQuationIndex });
        }
        const shareParam = urlParams.get("assessmentId");
        if (shareParam && publicAssessmentData) {
          setIsStarted(true);
        }

        if (publicAssessmentData?.feedbackReview) {
          setIsFeedback(true);
        }
        setAssessmentData(
          assessmentData ? assessmentData : publicAssessmentData ? publicAssessmentData : AssessmentData
        );

        if (publicAssessmentData?.answers?.length > 0) {
          setIsStarted(true);
        }
        if (publicAssessmentData) {
          let crtQuation = assessmentData
            ? assessmentData?.questionsList[assessmentData.answers?.length]
            : publicAssessmentData?.questionsList[publicAssessmentData.answers?.length];

          let currentQuationIndex = assessmentData
            ? assessmentData?.answers?.length
            : publicAssessmentData?.answers?.length;

          setCurrentQuestion({ crtQuation, currentQuationIndex: currentQuationIndex });
        }
        if (publicAssessmentData?.isSubmit == true) {
          setIsSubmitted(true);
          setShowSummary(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, [isStarted, isFeedback, assessmentData]);

  if (loading) {
    return <></>;
  }

  if (showSummery && isStarted && assessmentData?.questionsList?.length > 0) {
    return <QuestionSummary currentQuestion={currentQuestion} setShowSummary={setShowSummary}></QuestionSummary>;
  }

  if (assessmentData?.jobInfo?.isActive === false) {
    return (
      <div className="assessmnet-Page" style={{ minHeight: "520px" }}>
        <InlineStack align="center">
          <Page>
            <Card>
              <BlockStack gap="400">
                <DeactivePage></DeactivePage>
              </BlockStack>
            </Card>
          </Page>
        </InlineStack>
      </div>
    );
  }

  if (!isStarted && !isSubmitted && !isError && assessmentData?.questionsList.length > 0) {
    return (
      <div className="assessmnet-Page" style={{ minHeight: "520px" }}>
        <Page>
          <InlineStack align="center">
            <InitialInstruction
              jobData={assessmentData?.jobInfo}
              questionsList={assessmentData?.questionsList}
              setIsStarted={setIsStarted}
              isStarted={isStarted}
              assessmentData={assessmentData}
              organizationData={organizationData}
              setAssessmentData={setAssessmentData}
              stageData={stage}
            />
          </InlineStack>
        </Page>
      </div>
    );
  }

  if (assessmentData?.questionsList.length === 0) {
    return (
      <div className="assessment-test assessmnet-Page" style={{ minHeight: "520px", paddingTop: "30px" }}>
        <Page>
          <BlockStack align="center" gap="200">
            <InlineStack gap="100" align="center">
              <Image width={50} source={RightIcon} />
            </InlineStack>
            <div className="assessment-complate">
              <Text variant="headingLg" alignment="center">
                Thank you for your response
              </Text>
              <br />
              <Text variant="bodyMd" alignment="center">
                Thank you for providing your details. We are currently reviewing the information you have submitted
                and will be in touch soon with further updates and additional information. We appreciate your
                patience as we work through this process.
              </Text>
            </div>
            <div className="assessment-done">
              <Divider borderColor="border" />
              <br />
              <Text variant="bodyMd" alignment="center">
                Stay tuned for updates on feedback and next steps.
              </Text>
            </div>
          </BlockStack>
        </Page>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="assessment-test assessmnet-Page" style={{ minHeight: "520px", paddingTop: "30px" }}>
        <Page>
          <BlockStack align="center" gap="200">
            <InlineStack gap="100" align="center">
              <Image width={50} source={RightIcon} />
            </InlineStack>
            <div className="assessment-complate">
              {isFeedback ? (
                <Text variant="headingLg" alignment="center">
                  Thank you for your feedback!
                </Text>
              ) : (
                <Text variant="headingLg" alignment="center">
                  Congratulations! You have successfully completed your interview
                </Text>
              )}
              <br />
              {isFeedback ? (
                <Text variant="bodyMd" alignment="center">
                  Your participation and thoughtful responses during the interview are greatly appreciated and mark
                  an important milestone in our selection process. We will be in touch soon with further details.
                </Text>
              ) : (
                <Review id={assessmentData._id} setIsFeedback={setIsFeedback}></Review>
              )}
            </div>
            <div className="assessment-done">
              <Divider borderColor="border" />
              <br />
              <Text variant="bodyMd" alignment="center">
                Stay tuned for updates on feedback and next steps.
              </Text>
            </div>
          </BlockStack>
        </Page>
      </div>
    );
  }

  if (assessmentData && currentQuestion) {
    return (
      <div className="assessment-test assessmnet-Page" style={{ minHeight: "520px", paddingTop: "30px" }}>
        <Page>
          <BlockStack gap="200">
            {!bannerShow && (
              <div className="worningBanner">
                <Banner
                  tone="warning"
                  title={`
            Please avoid switching to another tab, as it may trigger a notification to your employer.`}
                  style={{ marginTop: "0px" }}
                />
              </div>
            )}
            <Card>
              <BlockStack inlineAlign="center" gap="400">
                <div
                  className={`${
                    currentQuestion?.crtQuation?.questionType == "video" &&
                    currentQuestion?.crtQuation?.answerType != "number"
                      ? "video-input"
                      : "other-input"
                  }`}
                >
                  <InterviewQuestion currentQuestion={currentQuestion} bannerShow={bannerShow} />
                  <InterviewAnswer
                    currentQuestion={currentQuestion}
                    handleNextQuestion={handleNextQuestion}
                    assessmentData={assessmentData}
                    setIsSubmitted={setIsSubmitted}
                    isPublic={isPublic}
                    countdown={countdown}
                    setCountdown={setCountdown}
                    setIsLoading={setLoading}
                  />
                </div>
              </BlockStack>
            </Card>
          </BlockStack>
        </Page>
      </div>
    );
  }
  return (
    <Page>
      <Card>
        <InlineStack align="center">
          <Text variant="headingMd">The assessment could not be found. </Text>
        </InlineStack>
      </Card>
    </Page>
  );
};
