import React, { useEffect, useState } from "react";
import { BlockStack, Button, Card, Divider, Image, InlineStack, List, Page, Text } from "@shopify/polaris";
import parse from "html-react-parser";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { RightIcon } from "@/Assets/Index";

export const AssessmentConformation = ({ candidateData, setAsssessmentStart, stage, id }) => {
  const fetch = useAuthenticatedFetch();
  const [mailSend, setMailSend] = useState(false);

  const sendEmail = async () => {
    let response = await fetch.post(
      "/sendInvitation",
      { assessment_id: id, candidateData: candidateData, stageTitle: stage?.stageDetails?.stageTitle },
      false
    );
    if (response?.data == true) {
      setMailSend(true);
    }
  };

  const startAssessment = async () => {
    setAsssessmentStart(true);
    await fetch.post("/sendInvitation", { assessmentConformation: true, id: candidateData?._id }, false);
  };

  useEffect(() => {
    if (candidateData?.sendEmail) {
      setMailSend(true);
    }
  }, [stage, candidateData]);

  if (mailSend) {
    return (
      <div
        className="assessment-test assessmnet-Page"
        style={{ minHeight: "520px", paddingTop: "30px", width: "100%" }}
      >
        <BlockStack align="center" gap="200">
          <InlineStack gap="100" align="center">
            <Image width={50} source={RightIcon} />
          </InlineStack>
          <div className="assessment-complate">
            <Text variant="headingLg" alignment="center">
              Thank you for your response
            </Text>
            <br />
            <Text variant="bodyMd" alignment="center">
              We have sent you an assessment link. Please complete the assessment when you are ready. Note that the
              link will expire in 48 hours.
            </Text>
          </div>
          <div className="assessment-done">
            <Divider borderColor="border" />
            <br />
            <Text variant="bodyMd" alignment="center">
              Stay tuned for updates on feedback and next steps.
            </Text>
          </div>
        </BlockStack>
      </div>
    );
  }

  return (
    <div className="ambvn">
      <Card>
        <BlockStack align="center" gap="200">
          <div className="assessment-complate" style={{ padding: "0px 180px" }}>
            <Text variant="headingLg" alignment="center">
              Would you like to start the assessment right away?
            </Text>
          </div>
          <div className="assessment-complate" style={{ padding: "0px 180px" }}>
            <InlineStack gap={200}>
              {stage?.stageDetails?.specialInstruction && (
                <Text variant="bodyMd" alignment="center">
                  {parse(stage?.stageDetails?.specialInstruction)}
                </Text>
              )}
              <Text alignment="center">
                If you choose yes, the assessment will begin immediately. If you choose no, we will send the
                assessment link via email.
              </Text>
            </InlineStack>
          </div>
          <InlineStack align="center">
            <InlineStack align="start" gap={100}>
              <Text as="p" tone="subdued" variant="bodyLg">
                Total Questions :
              </Text>
              <Text fontWeight="bold" variant="bodyLg">
                {(stage && stage?.stageDetails?.totalQuestions ? stage?.stageDetails?.totalQuestions : 0) +
                  (stage && stage?.stageDetails?.questionList ? stage?.stageDetails?.questionList?.length : 0)}
              </Text>
            </InlineStack>
          </InlineStack>
          <InlineStack gap="200" align="center">
            <Button variant="primary" size="large" onClick={startAssessment}>
              Yes
            </Button>
            <Button variant="primary" size="large" onClick={sendEmail}>
              No
            </Button>
          </InlineStack>
        </BlockStack>
      </Card>
    </div>
  );
};
