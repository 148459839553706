export const candidatePublicUrlFields = (publicUrlField) => {
  let fields = [
    {
      id: "publicUrlField",
      name: "publicUrlField",
      label: "Candidate Field",
      nested: "object",
      groupSize: 2,
      section: true,
      subfields: [
        {
          id: "fullName",
          name: "fullName",
          label: "Full Name",
          type: "checkbox",
          dependOn: {
            name: "publicUrlField.isPublicUrl",
            value: true,
            type: "disabled",
          },
        },
        {
          id: "email",
          name: "email",
          label: "Email Address",
          type: "checkbox",
          dependOn: {
            name: "publicUrlField.isPublicUrl",
            value: true,
            type: "disabled",
          },
        },
        {
          id: "mobile",
          name: "mobile",
          label: "Mobile",
          type: "checkbox",
          dependOn: {
            name: "publicUrlField.isPublicUrl",
            value: true,
            type: "disabled",
          },
        },
        {
          nested: "group",
          groupSize: 3,
          section: false,
          subfields: [
            {
              id: "resume",
              name: "resume",
              label: "Resume",
              type: "checkbox",
            },
            {
              id: "resumeRequered",
              name: "resumeRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.resume",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 3,
          section: false,
          subfields: [
            {
              id: "preferWorkMode",
              name: "preferWorkMode",
              label: "Prefer Work Mode",
              type: "checkbox",
            },
            {
              id: "preferWorkModeRequered",
              name: "preferWorkModeRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.preferWorkMode",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "currentCTC",
              name: "currentCTC",
              label: "Current CTC",
              type: "checkbox",
            },
            {
              id: "currentCTCRequered",
              name: "currentCTCRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.currentCTC",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "expectedCTC",
              name: "expectedCTC",
              label: "Expected CTC",
              type: "checkbox",
            },
            {
              id: "expectedCTCRequered",
              name: "expectedCTCRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.expectedCTC",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "noticePeriod",
              name: "noticePeriod",
              label: "Notice Period",
              type: "checkbox",
            },
            {
              id: "noticePeriodRequered",
              name: "noticePeriodRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.noticePeriod",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "currentLocation",
              name: "currentLocation",
              label: "Current Location",
              type: "checkbox",
            },
            {
              id: "currentLocationRequered",
              name: "currentLocationRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.currentLocation",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "totalExperience",
              name: "totalExperience",
              label: "Total Experience",
              type: "checkbox",
            },
            {
              id: "totalExperienceRequered",
              name: "totalExperienceRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.totalExperience",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "changeLocation",
              name: "changeLocation",
              label: "Change Location",
              type: "checkbox",
            },
            {
              id: "changeLocationRequered",
              name: "changeLocationRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.changeLocation",
                value: true,
                type: "hidden",
              },
            },
          ],
        },

        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "skills",
              name: "skills",
              label: "Skills",
              type: "checkbox",
            },
            {
              id: "skillsRequered",
              name: "skillsRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.skills",
                value: true,
                type: "hidden",
              },
            },
          ],
        },

        {
          nested: "group",
          groupSize: 2,
          section: false,
          subfields: [
            {
              id: "developerType",
              name: "developerType",
              label: "Employment Type",
              type: "checkbox",
            },
            {
              id: "developerTypeRequered",
              name: "developerTypeRequered",
              label: "Required",
              type: "checkbox",
              dependOn: {
                name: "publicUrlField.developerType",
                value: true,
                type: "hidden",
              },
            },
          ],
        },
      ],
    },
    {
      id: "recruiterContect",
      name: "recruiterContect",
      label: "Choose the platform for Contact the recruiter",
      nested: "object",
      groupSize: 3,
      section: true,
      subfields: [
        {
          id: "emailAccess",
          name: "emailAccess",
          label: "Email",
          type: "checkbox",
        },
        {
          id: "phoneAccess",
          name: "phoneAccess",
          label: "Phone",
          type: "checkbox",
        },
        {
          id: "whatsappAccess",
          name: "whatsappAccess",
          label: "Whatsapp",
          type: "checkbox",
        },
      ],
    },
    {
      id: "AssessmentFollowup",
      name: "AssessmentFollowup",
      label: "Choose the platform for sending the invite and follow-up",
      nested: "object",
      groupSize: 2,
      section: true,
      subfields: [
        {
          id: "email",
          name: "email",
          label: "Email",
          type: "checkbox",
        },
        {
          id: "whatsapp",
          name: "whatsapp",
          label: "Whatsapp",
          type: "checkbox",
        },
      ],
    },
  ];
  if (publicUrlField) {
    fields.push({
      id: "resumeScore",
      name: "resumeScore",
      label: "You want resume score right away by AI. ",
      type: "checkbox",
    });
  }
  return fields;
};

export const candidatePublicUrlInitialValues = {
  publicUrlField: {
    fullName: true,
    email: true,
    mobile: true,
    platform: false,
    resume: false,
    preferWorkMode: false,
    currentCTC: false,
    expectedCTC: false,
    noticePeriod: false,
    currentLocation: false,
    totalExperience: false,
    changeLocation: false,
    isPublicUrl: true,
    totalExperienceRequered: false,
    currentLocationRequered: false,
    noticePeriodRequered: false,
    expectedCTCRequered: false,
    currentCTCRequered: false,
    preferWorkModeRequered: false,
    changeLocationRequered: false,
    resumeRequered: false,
    skills: false,
    skillsRequered: false,
    developerTypeRequered: false,
    developerType: false,
  },
  recruiterContect: {
    emailAccess: false,
    phoneAccess: false,
    whatsappAccess: false,
  },
  AssessmentFollowup: {
    email: false,
    whatsapp: false,
  },
  resumeScore: false,
};
