import React, { useContext, useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import i18n from "i18next";
import DeletedUser from "@/App/Pages/Admin/DeletedUser.jsx";
import GetData from "@/App/Pages/Admin/GetData.jsx";
import AdminLogin from "@/App/Pages/Admin/Login.jsx";
import Organization from "@/App/Pages/Admin/Organization.jsx";
import User from "@/App/Pages/Admin/User.jsx";
import { UserContext } from "@/Context/UserContext";
import { logger } from "@/Services/Logger/Index";
import { isAdmin, setSessionStorageItem } from "@/Utils/Index";
import { SumoApp } from "../Pages/AppSumo/appSumoCode.jsx";
import { AssessmentPreview } from "../Pages/InterviewsByAI/Assessment/AssessmentPreview.jsx";
import { AssessmentTest } from "../Pages/InterviewsByAI/Assessment/InviteAssessment/AssessmentTest.jsx";
import { EmailAssessment } from "../Pages/InterviewsByAI/Assessment/InviteAssessment/EmailAssessment.jsx";
import { StageAssessment } from "../Pages/InterviewsByAI/Assessment/StageAssessment/StageAssessment.jsx";
import { Home } from "../Pages/InterviewsByAI/Home/Home.jsx";
import { ForgotPassword } from "../Pages/InterviewsByAI/Login/ForgotPassword.jsx";
import { ResetPassword } from "../Pages/InterviewsByAI/Login/ResetPassword.jsx";
import { AddOrganization } from "../Pages/InterviewsByAI/Organization/AddOrganization.jsx";
import { FirstOrganization } from "../Pages/InterviewsByAI/Organization/Organization.jsx";
import { Resourcing } from "../Pages/InterviewsByAI/Resourcing/Resourcing.jsx";
import { ResumeFormatter } from "../Pages/InterviewsByAI/Resume/ResumeFormatter.jsx";
import { ResumePreview } from "../Pages/InterviewsByAI/Resume/ResumePreview.jsx";
import { Settings } from "../Pages/InterviewsByAI/Settings/Settings.jsx";
import { VerifyToken } from "../Pages/InterviewsByAI/SignUp/VerifyToken.jsx";
import { StageDetails } from "../Pages/InterviewsByAI/Stage/StageDetails.jsx";
import { TemplateList } from "../Pages/InterviewsByAI/Stage/templates/TemplateList.jsx";
import Pricing from "../Pages/Plan/Plan.jsx";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions.jsx";
import { Access } from "./../Pages/InterviewsByAI/Access/Access.jsx";
import { CandidateDetail } from "./../Pages/InterviewsByAI/Candidate/CandidateDetail.jsx";
import { JobDetail } from "./../Pages/InterviewsByAI/Jobs/JobDetail.jsx";
import { Jobs } from "./../Pages/InterviewsByAI/Jobs/Jobs.jsx";
import { Login } from "./../Pages/InterviewsByAI/Login/Login.jsx";
import { SignUp } from "./../Pages/InterviewsByAI/SignUp/SignUp.jsx";
import NotFound from "./../Pages/NotFound/NotFound.jsx";
import { AppRoute, GeneralRoute, PrivateAdminRoute, PublicAdminRoute } from "./ProtectedRoutes.jsx";

const PublicRouteWrapper = () => {
  const { userProfileData } = useContext(UserContext);

  useEffect(() => {
    const initializeI18n = async () => {
      try {
        await i18n.use(initReactI18next).init();

        let languageToLoad = "en"; // Default to English
        if (!isAdmin() && userProfileData?.appLanguage) {
          languageToLoad = userProfileData.appLanguage;
        }
        const languageResource = await import(`@/LanguageJS/${languageToLoad}.js`);
        i18n.addResourceBundle(languageToLoad, "translation", languageResource.default);
        await i18n.changeLanguage(languageToLoad);
        setSessionStorageItem("appLanguage", languageToLoad);
      } catch (error) {
        logger.error(error);
      }
    };

    initializeI18n();
  }, [userProfileData?.appLanguage, i18n]);

  return (
    <Routes>
      <Route element={<GeneralRoute />}>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />
        <Route path="/access" element={<Access />} />
        <Route path="/assessment/:id" element={<EmailAssessment />} />
        <Route path="/verify" element={<VerifyToken />} />
        <Route exact path="/candidate/share/:id" element={<CandidateDetail isPublic={true} />} />
        <Route exact path="/stage/share/:id" element={<StageAssessment />} />
      </Route>
      <Route element={<AppRoute type="public" />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<AppRoute type="private" />}>
        <Route exact path="/jobs" element={<Jobs />} />
        <Route exact path="/jobs/:id" element={<JobDetail />} />
        <Route exact path="/stage/:id" element={<StageDetails />} />
        <Route exact path="/candidate/:id" element={<CandidateDetail isPrivate={true} />} />
        <Route exact path="/assessment/:id" element={<AssessmentTest />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/appSumo" element={<SumoApp />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/organization" element={<AddOrganization />} />
        <Route exact path="/add-organization" element={<FirstOrganization />} />
        <Route exact path="/assessment" element={<AssessmentPreview />} />
        <Route exact path="/resume" element={<ResumeFormatter />} />
        <Route exact path="/resume/:id" element={<ResumePreview />} />
        <Route exact path="/templates" element={<TemplateList />} />
        <Route exact path="/resourcing" element={<Resourcing />} />

        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<PublicAdminRoute />}>
        <Route path="/admin/login" element={<AdminLogin />} />
      </Route>
      <Route element={<PrivateAdminRoute />}>
        <Route path="/admin/user" element={<User />} />
        <Route path="/admin/organization" element={<Organization />} />
        <Route path="/admin/deleteuser" element={<DeletedUser />} />
        <Route path="/admin/getdata" element={<GetData />} />
      </Route>
    </Routes>
  );
};

export default PublicRouteWrapper;
