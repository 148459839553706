import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Badge, BlockStack, Button, IndexTable, InlineStack, Link, Modal, Text } from "@shopify/polaris";
import { ClipboardMinor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { normalScreeningInitialValues, stageInitialValues } from "@/Assets/Mocks/StageDetails.mock";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { candidatePublicUrlInitialValues } from "../../../../Assets/Mocks/StagePublicUrl.mock";
import { AccessPermission } from "../../../../Components/Common/AccessPermission";
import { AddInterview } from "../AddInterview";
import { PublicStage } from "../PublicStage";

export function TemplateList(props) {
  const fetch = useAuthenticatedFetch();
  const [templates, setTemplates] = useState([]);
  const childRef = useRef();
  const [pagination, setParentPagination] = useState(null);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showAddInterview, setShowAddInterview] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [backUpStageValue, setBackUpStageValue] = useState({});
  const [actionType, setActionType] = useState();
  const [showTemplateSaveButton, setShowTemplateSaveButton] = useState(true);
  const { showToast } = useContext(ToastContext);
  const [isPublic, setIsPublicOpen] = useState(false);
  const [isPublicEdit, setIsPublicEdit] = useState(false);
  const [publicUrl, setPublicUrl] = useState(false);
  const [publicUrlFormValues, SetPublicUrlFormValues] = useState(candidatePublicUrlInitialValues);

  const getTemplates = useCallback(async () => {
    const response = await fetch.get("/stageTemplates");
    if (response?.data) {
      setTemplates(response.data);
    }
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);
  const handleDeleteConfirmed = useCallback(async () => {
    if (stageToDelete) {
      await fetch.delete(`/stage/${stageToDelete._id}`);
      childRef.current.fetchData();
      showToast("Interview Template Delete successfully");
      setIsDeleteModalOpen(false);
    }
  }, [stageToDelete, childRef]);

  const handleDelete = async (row) => {
    setStageToDelete(row);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteCanceled = () => {
    setStageToDelete(null);
    setIsDeleteModalOpen(false);
  };
  const handleEditStage = useCallback(async (row) => {
    setShowTemplateSaveButton(false);
    setActionType("update");
    const data = { ...stageInitialValues, ...normalScreeningInitialValues, ...row };
    setSelectedRow(data);
    setShowAddInterview(true);
  }, []);
  const handleButtonClick = useCallback(async () => {
    setShowTemplateSaveButton(true);
    setActionType("add");
    setShowAddInterview(true);
  }, []);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const handlePublicUrl = async (row) => {
    if (row?.publicUrlField?.resume) {
      setPublicUrl(true);
    }
    SetPublicUrlFormValues({
      ...candidatePublicUrlInitialValues,
      ...row,
    });
    setIsPublicEdit(row?.publicUrlField ? true : false);
    setIsPublicOpen(true);
  };

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p" fontWeight="medium">
                {row?.stageDetails?.label ? row?.stageDetails?.label : row?.stageDetails?.stageTitle}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p" fontWeight="medium">
                {row?.stageDetails?.stageTitle}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p" fontWeight="medium">
                {row?.stageDetails?.stagePreset}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p" fontWeight="medium">
                {formatTimeAgo(row?.created)}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{row?.userdetail[0]?.firstName}</IndexTable.Cell>
            <IndexTable.Cell>
              {row?.isPublic || row?.publicUrl ? (
                <InlineStack gap={200}>
                  <Badge tone="success">Public</Badge>
                  <Button
                    plain
                    monochrome
                    icon={EditMinor}
                    onClick={() => {
                      handlePublicUrl(row);
                    }}
                  />
                </InlineStack>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    handlePublicUrl(row);
                  }}
                >
                  Public
                </Button>
              )}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <AccessPermission access={["manageJobs", "manageCandidate"]}>
                    <Button
                      plain
                      monochrome
                      icon={EditMinor}
                      onClick={() => {
                        handleEditStage(row);
                      }}
                    />
                    <Button
                      plain
                      monochrome
                      icon={DeleteMinor}
                      onClick={() => {
                        handleDelete(row);
                      }}
                    />
                  </AccessPermission>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [pagination]
  );

  return (
    <>
      <InlineStack align="end">
        <Button size="large" onClick={handleButtonClick}>
          Add Interview Template
        </Button>
      </InlineStack>
      <CommonTable
        resourceName={{
          singular: "Template",
          plural: "Templates",
        }}
        title="Interview Templates Stages"
        queryPlaceholder="Search Interview Templates by (Template Title, interview Title)"
        selectable={false}
        url={`/interviewTemplates`}
        isFilterVisible
        rowsData={createRowsData}
        headings={[
          { title: "No" },
          { title: "Template Title" },
          { title: "interview Title" },
          { title: "Type" },
          { title: "Created At" },
          { title: "Created By" },
          { title: "Public" },
          { title: "Action" },
        ]}
        searchKey={["stageDetails.stageTitle", "stageDetails.label"]}
        ref={childRef}
        isAdd={false}
        setParentPagination={setParentPagination}
      />
      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteCanceled}
        title="Confirm Delete"
        primaryAction={{
          content: "Delete",
          onAction: handleDeleteConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleDeleteCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to delete this Interview Template?</Text>
        </Modal.Section>
      </Modal>
      {showAddInterview && (
        <AddInterview
          row={selectedRow}
          setBackUpStageValue={setBackUpStageValue}
          backUpStageValue={backUpStageValue}
          stageData={selectedRow?.stageDetails}
          actionType={actionType}
          selectedStage={selectedRow}
          setShowAddInterview={setShowAddInterview}
          status="Template"
          showTemplateSaveButton={showTemplateSaveButton}
          childRef={childRef}
        />
      )}
      {isPublic && (
        <PublicStage
          SetPublicUrlFormValues={SetPublicUrlFormValues}
          publicUrlFormValues={publicUrlFormValues}
          isPublicEdit={isPublicEdit}
          publicUrl={publicUrl}
          setPublicUrl={setPublicUrl}
          childRef={childRef}
          setIsPublicOpen={setIsPublicOpen}
        ></PublicStage>
      )}
    </>
  );
}
