import React, { useCallback, useEffect, useRef, useState } from "react";
import { BlockStack, Button, InlineStack, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { VideoDisplay } from "@/Components/Common/VideoPlayer";
import { uploadFileToServer } from "@/Utils/Utils";
import { getFields } from "../../../../Assets/Mocks/StageDetails.mock";

export const SingleFormAssessment = ({ assessmentData, setIsSubmitted, stage }) => {
  const [initialvalue, setValue] = useState(getFields("single")?.["initialValues"]);
  const [valueFields, setValueFields] = useState(getFields("single")?.["fields"]);
  const formRefs = useRef([]);
  const fetch = useAuthenticatedFetch();
  const [assessment, setAssessment] = useState(assessmentData);
  const [questionCount, setQuestionCount] = useState(0);
  const [isSubmitDisable, setisSubmitDisable] = useState(true);
  const [answerlist, setAnswerlist] = useState([]);
  const [videType, setvideoType] = useState(0);

  const uploadFile = async (data) => {
    const uploadedVideo = await uploadFileToServer(fetch, data);
    if (uploadedVideo) {
      return { uploadedVideo: uploadedVideo };
    }
  };
  useEffect(() => {
    const videoAnswerTypeCount =
      assessment?.questionsList?.filter((question) => question?.answerType === "video").length || 0;
    const aiGeneratedCount =
      assessment?.questionsList?.filter((question) => question?.aiGenerated === true).length || 0;
    const totalVideoAndAiGeneratedCount = videoAnswerTypeCount + aiGeneratedCount;
    setvideoType(totalVideoAndAiGeneratedCount);
    setQuestionCount(assessment?.answers?.length);
    setisSubmitDisable(totalVideoAndAiGeneratedCount === assessment?.answers?.length ? false : true);
  }, [assessment, questionCount]);

  const handleAssessment = async (values, index, value) => {
    let answer = {
      question: value?.questionVideo?.assetUrl ? value?.questionVideo?.assetUrl : value?.question,
      aiEvaluation: value?.aiEvaluation || value?.aiGenerated,
      currentQuestionIndex: index,
      isProcessing: true,
    };
    if (values?.answerVideo?.recordedBlob) {
      let lastQuation = index == assessmentData?.questionsList?.length - 1;
      const data = {
        file: values.answerVideo.recordedBlob,
        fileName: `video-${assessmentData?._id}-${Date.now()}.webm`,
        isPublic: true,
        convertToVideo: true,
        lastQuation,
        assessment_id: assessmentData?._id,
        answer: answer,
      };
      let fileData = await uploadFile(data);
      if (fileData?.uploadedVideo?.assetUrl) {
        answer.answerVideoText = "";
        answer.answerVideoURL = fileData?.uploadedVideo?.assetUrl || null;
        answer.videoURL = values?.answerVideo?.videoURL;
        answer.duration = values?.answerVideo?.duration;
        let requestData = {
          isSubmit: false,
          answers: answer,
          assessment_id: assessmentData?._id,
          currentQuestionIndex: index,
        };

        let res = await fetch.post("/assessment", requestData, lastQuation);
        setAssessment(res?.data);
        setQuestionCount(questionCount + 1);
      }
    }
  };

  const submitForm = useCallback(async () => {
    let requestData = {
      isSubmit: true,
      assessment_id: assessmentData?._id,
      isSingleform: true,
    };
    if (stage?.stageDetails?.stagePreset === "aiScreening") {
      let res = await fetch.post("/assessment", requestData, true);
      setIsSubmitted(true);
    } else {
      setAnswerlist([]);
      for (const formRef of formRefs.current) {
        if (formRef.current) {
          await formRef.current.handleSubmit();
        }
      }
    }
  }, []);
  useEffect(() => {
    if (
      stage?.stageDetails?.stagePreset === "bothScreening" &&
      answerlist.length + questionCount === formRefs?.current?.length
    ) {
      saveAssessment();
    } else if (
      answerlist.length + videType === formRefs?.current?.length &&
      answerlist.length > 0 &&
      stage?.stageDetails?.stagePreset !== "aiScreening"
    ) {
      saveAssessment();
    }
  }, [answerlist, formRefs.current, questionCount, videType]);

  const saveAssessment = async () => {
    let requestData = {
      isSubmit: true,
      assessment_id: assessmentData?._id,
      isSingleform: true,
    };
    requestData.answers = answerlist;
    let res = await fetch.post("/assessment", requestData, true);
    setIsSubmitted(true);
    setAnswerlist([]);
  };

  const convertText = async (answer) => {
    let stringAns = answer.join(", ");
    return stringAns;
  };

  const handleSubmit = useCallback(async (e, index, value) => {
    let answer = {
      question: value?.question,
      aiEvaluation: value?.aiEvaluation || value?.aiGenerated,
      currentQuestionIndex: index,
      isProcessing: true,
    };
    answer.answerVideoText = typeof e.textAns === "object" ? await convertText(e.textAns) : e.textAns;
    setAnswerlist((prevAnswerList) => [...prevAnswerList, answer]);
  }, []);

  const infoCard = (value, index) => {
    const fields = getFields(value, "single")?.["fields"];
    const initialValues = getFields(value, "single")?.["initialValues"];
    if (!formRefs.current[index]) {
      formRefs.current[index] = React.createRef();
    }
    return (
      <div style={{ padding: "10px" }} key={index}>
        <BlockStack gap={"400"}>
          <div className="quations">
            {value?.questionType == "video" ? (
              <InlineStack align="start" gap={400}>
                <Text variant="headingMd">{`Question ${index + 1}: ${value?.question}`}</Text>
                <VideoDisplay
                  url={`${process.env.APP_URL}/uploads/${value?.questionVideo?.assetUrl}`}
                  duration={Math.floor(value?.questionVideo?.duration)}
                  settings={{
                    width: "400px",
                    height: "320px",
                  }}
                />
              </InlineStack>
            ) : (
              <InlineStack align="start" gap={400}>
                <Text variant="headingMd">{`Question ${index + 1}: ${value?.question}`}</Text>
              </InlineStack>
            )}
          </div>
          {value?.answerType === "video" || value?.aiGenerated ? (
            <InlineStack gap={200} align="center">
              {value?.answer?.answerVideoURL ? (
                <VideoDisplay
                  url={`${process.env.APP_URL}/uploads/${value?.answer?.answerVideoURL}`}
                  settings={{ height: "320px", width: "400px" }}
                />
              ) : (
                <CommonForm
                  formFields={fields}
                  isSave={false}
                  formRef={formRefs.current[index]} // Use the current form ref
                  initialValues={initialValues}
                  noValueChanged={false}
                  enableReinitialize={true}
                  onSubmit={(formValues) => handleSubmit(formValues, index, value)}
                  onFormChange={(formValues) => handleAssessment(formValues, index, value)}
                />
              )}
            </InlineStack>
          ) : (
            <CommonForm
              formFields={fields}
              isSave={false}
              formRef={formRefs.current[index]} // Use the current form ref
              initialValues={initialValues}
              noValueChanged={false}
              onSubmit={(formValues) => handleSubmit(formValues, index, value)}
              enableReinitialize={true}
            />
          )}
        </BlockStack>
      </div>
    );
  };

  return (
    <>
      {assessment.questionsList.map((e, index) => infoCard(e, index))}
      <InlineStack align="center">
        <Button disabled={isSubmitDisable} onClick={submitForm} variant="primary" size="micro">
          Submit
        </Button>
      </InlineStack>
    </>
  );
};
