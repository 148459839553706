import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Badge, BlockStack, Button, Icon, Image, InlineStack, Link, Page, Text } from "@shopify/polaris";
import { DesktopMajor, JobsMajor, LocationsMinor, OrganizationMajor } from "@shopify/polaris-icons";
import TruncatedText from "@/Components/Common/TruncatedText";

export const CommonHeader = (props) => {
  const { jobData, stage, assessmentPreviews, organizationData } = props;
  const [imgUrl, setImgUrl] = useState();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [fullPage, setIsFullPage] = useState(stage?.publicUrl ? true : false);

  useEffect(() => {
    if (assessmentPreviews?.logo?.length == 0) {
      setImgUrl();
    } else if (assessmentPreviews?.logo?.url) {
      const url = assessmentPreviews?.logo?.url;
      let imageUrl = url;
      setImgUrl(imageUrl);
    } else if (organizationData?.assessmentPreview?.logo?.url) {
      const url = organizationData?.assessmentPreview?.logo?.url;
      let imageUrl = url;
      setImgUrl(imageUrl);
    }
  }, [imgUrl, jobData, assessmentPreviews, organizationData]);

  useEffect(() => {
    const candiateParam = urlParams.get("candidateId");
    if (candiateParam) {
      setIsFullPage(false);
    } else if (stage?.publicUrl) {
      setIsFullPage(true);
    }
  }, [urlParams]);

  return (
    <>
      {jobData?.jobDescription && (
        <div
          className="page-header"
          style={{
            background: "#F1F4FF",
            borderBottom: "1px solid #22465F",
            padding: "20px",
            width: "100%",
          }}
        >
          <Page fullWidth={fullPage}>
            <BlockStack gap="100">
              <InlineStack gap="400" align="start">
                {imgUrl && <Image width={60} source={imgUrl ? imgUrl : ""} />}
                <BlockStack gap="100">
                  <InlineStack gap="400" align="start">
                    <div className="JobTitle">
                      <TruncatedText text={jobData?.jobTitle} maxLength={60} />
                    </div>
                  </InlineStack>
                  <InlineStack gap="800" align="start">
                    <BlockStack gap="200" inlineAlign="center">
                      <InlineStack gap="200">
                        {organizationData?.assessmentPreview?.title ||
                          (assessmentPreviews?.title && (
                            <div className="company-title">
                              {organizationData?.website ? (
                                <Link url={organizationData?.website} target="_blank" removeUnderline>
                                  {assessmentPreviews?.title
                                    ? assessmentPreviews?.title
                                    : organizationData?.assessmentPreview?.title}
                                </Link>
                              ) : (
                                <Text fontWeight="bold">
                                  {assessmentPreviews?.title
                                    ? assessmentPreviews?.title
                                    : organizationData?.assessmentPreview?.title}
                                </Text>
                              )}
                            </div>
                          ))}
                        {jobData?.jobLocation && (
                          <InlineStack align="start" blockAlign="center">
                            <Icon color="subdued" source={LocationsMinor} tone="base" />
                            <Text variant="bodyLg" as="p" tone="subdued">
                              {jobData?.jobLocation}
                            </Text>
                          </InlineStack>
                        )}
                        <InlineStack align="start" gap="600">
                          <InlineStack gap="200" align="start">
                            {jobData?.employmentType && (
                              <InlineStack align="start" gap="200">
                                <Icon color="subdued" source={OrganizationMajor} tone="base" />
                                <div className="job-icon">
                                  <Text variant="bodyLg" as="p">
                                    {jobData?.employmentType}
                                  </Text>
                                </div>
                              </InlineStack>
                            )}
                            {jobData?.workMode && (
                              <InlineStack align="start" gap="200">
                                <Icon color="subdued" source={DesktopMajor} tone="base" />
                                <div className={jobData?.workExperience ? "job-icon" : ""}>
                                  <Text variant="bodyLg" as="p">
                                    {jobData?.workMode}
                                  </Text>
                                </div>
                              </InlineStack>
                            )}
                            {jobData?.workExperience && (
                              <InlineStack align="start" gap="200">
                                <Icon color="subdued" source={JobsMajor} tone="base" />
                                <InlineStack align="start" gap={100}>
                                  <Text variant="bodyLg" as="p">
                                    {jobData?.workExperience}
                                  </Text>
                                  {jobData?.MaxWorkExperience && (
                                    <Text variant="bodyLg" as="p">
                                      - {jobData?.MaxWorkExperience} years
                                    </Text>
                                  )}
                                </InlineStack>
                              </InlineStack>
                            )}
                          </InlineStack>
                        </InlineStack>
                      </InlineStack>
                    </BlockStack>
                  </InlineStack>
                </BlockStack>
              </InlineStack>
            </BlockStack>
          </Page>
        </div>
      )}
    </>
  );
};
