import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Text } from "@shopify/polaris";
import ErrorImg from "@/Assets/Images/Error.png";

export default function FallbackUIComponent() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <br />
      <img src={ErrorImg} alt="error" style={{ width: "200px" }} />
      <br />
      <Text variant="heading2xl" as="h2" tone="critical">
        {t("common.FallbackUiComponent.Oops! Something went wrong.")}
      </Text>
      <br />
      <Text variant="headingMd" as="h4" tone="caution">
        {t("common.FallbackUiComponent.Let's try that again, please refresh the page.")}
      </Text>
      <Text variant="headingMd" as="h4" tone="caution">
        {t("common.FallbackUiComponent.If you're still stuck, contact us by chat support or")} &nbsp;
        <Link url="mailto:support@webrexstudio.com" target="_blank">
          {t("common.FallbackUiComponent.support@webrexstudio.com")}
        </Link>
        &nbsp;{t("common.FallbackUiComponent.so we can help!")}
      </Text>
    </div>
  );
}
