import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockStack, Navigation } from "@shopify/polaris";
import {
  AppsMajor,
  BlogMajor,
  JobsMajor,
  LogOutMinor,
  PriceLookupMinor,
  ResourcesMajor,
  SettingsMajor,
  TemplateMajor,
} from "@shopify/polaris-icons";
import { useAuth } from "@/Context/AuthContext";
import { UserContext } from "../Context/UserContext";

function NavigationMenu({ setUseEffectCompleted, setIsNavigationVisible }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const { logout } = useAuth();
  const { userProfileData } = useContext(UserContext);

  const handleLogout = () => {
    logout();
  };

  const menu = [
    {
      label: "Jobs",
      icon: JobsMajor,
      destination: "/jobs",
    },
    {
      label: "Plans",
      icon: PriceLookupMinor,
      destination: "/pricing",
    },
    {
      label: "Interview Templates",
      icon: TemplateMajor,
      destination: "/templates",
    },
    {
      label: "Resourcing",
      icon: AppsMajor,
      destination: "/resourcing",
    },
    // {
    //   label: "Resume",
    //   icon: AppsMajor,
    //   destination: "/resume",
    // },
    // {
    //   label: "Tempaltes",
    //   icon: AppsMajor,
    //   destination: "/templates",
    // },
    {
      label: "Branding",
      icon: BlogMajor,
      destination: "/assessment",
    },
    {
      label: "Settings",
      icon: SettingsMajor,
      destination: "/settings",
    },
  ];

  const handleClick = useCallback((item) => {
    if (userProfileData?.pricingPlan?.isTrial == false) {
      if (item.destination == "/settings") {
        navigate("/settings");
        setSelected("/settings");
      } else {
        navigate("/pricing");
        setSelected("/pricing");
      }
    } else {
      navigate(item.destination);
      setSelected(item.destination);
    }
    setIsNavigationVisible(false);
    setUseEffectCompleted(false);
  }, []);

  useEffect(() => {
    const selectedItem = menu.find((item) => {
      if (location.pathname.startsWith("/candidate") && item.destination === "/jobs") {
        return true;
      }
      return location.pathname.startsWith(item.destination);
    });
    setSelected(selectedItem?.destination);
  }, [location]);
  return (
    <Navigation location={location.pathname}>
      <BlockStack>
        <Navigation.Section
          items={[
            ...menu.map((item) => ({
              ...item,
              onClick: () => handleClick(item),
              selected: selected === item.destination,
            })),
            {
              label: "Log Out",
              icon: LogOutMinor,
              onClick: handleLogout,
            },
          ]}
        />
      </BlockStack>
    </Navigation>
  );
}

export default NavigationMenu;
