import React from "react";
import { BlockStack, Button, Card, Image, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { WrongIcon } from "@/Assets/Index";

export const ExpiredPage = ({ publicAssessmentData, setaAssessmentExpired }) => {
  const fetch = useAuthenticatedFetch();

  const reactiveJob = async () => {
    const res = await fetch.post(`/assessment`, { assessment_id: publicAssessmentData?._id, isExpire: true });
    setaAssessmentExpired(true);
  };

  return (
    <Page>
      <Card>
        <BlockStack align="center" gap="200">
          <InlineStack gap="100" align="center">
            <Image width={50} source={WrongIcon} />
          </InlineStack>
          <div className="assessment-complate">
            <Text variant="headingLg" alignment="center">
              The assessment period has expired
            </Text>
            <br />
            <Text variant="bodyMd" alignment="center">
              The job remains open, and you can reactivate the assessment if required.
            </Text>
            <br />
            <InlineStack gap="100" align="center">
              <Button onClick={reactiveJob}>Reactive Assessment</Button>
            </InlineStack>
          </div>
        </BlockStack>
      </Card>
    </Page>
  );
};
