import React, { useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockStack, Button, Card, InlineStack, Text } from "@shopify/polaris";
import Bowser from "bowser";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { logger } from "@/Services/Logger/Index";
import {
  candidateDetailsPublicInitialValues,
  getCandidateDetailFields,
} from "../../../../Assets/Mocks/Candidate.mock";

export const CandidateForm = (props) => {
  const { jobData, stage, setasssessmentConformPage, setCandidate, setIsEmail, setAsssessmentStart } = props;
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const getUrlParam = (param) => urlParams.get(param);

  const submitForm = useCallback(async () => {
    if (formRef.current) {
      await formRef.current.handleSubmit();
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    try {
      let data = [];
      if (stage?.AssessmentFollowup) {
        for (const key in stage?.AssessmentFollowup) {
          if (stage?.AssessmentFollowup[key]) {
            data.push(key);
          }
        }
      }
      const platform = getUrlParam("platform");
      const referrel = getUrlParam("referrer");
      const browser = Bowser.getParser(window.navigator.userAgent);
      values["stages"] = [];
      values["stages"][0] = stage;
      values["platform"] = platform;
      values["isOnline"] = true;
      values["referrer"] = referrel;
      values["browser"] = browser?.parsedResult?.browser?.name;
      values["os"] = browser?.parsedResult?.os?.name;
      values["device"] = browser?.parsedResult?.platform?.vendor;
      values["assessmentSendType"] = data;

      let res = await fetch.post("/postCandidate", {
        jobId: stage.jobId,
        orgId: stage.orgId,
        userId: stage.userId,
        stageId: stage._id,
        ...values,
      });
      let candidate = res?.data?.candidateDetail ? res?.data?.candidateDetail : res?.data;
      if (res?.data?.email === true) {
        candidate["assessmentId"] = candidate?.stages[candidate.stages.length - 1]?.assessmentId;
      }
      if (stage?.resumeScore && candidate?.resume?.assetUrl) {
        const resumeData = {
          jobData,
          candidateResume: candidate?.resume?.assetUrl,
          candidateId: candidate?._id,
          candidate,
        };
        fetch.post(`/candidateResumeScore`, resumeData, false);
      }
      if (candidate?.assessmentId && candidate?._id) {
        localStorage.setItem("assessmentId", candidate?.assessmentId);
        localStorage.setItem("candidateId", candidate?._id);
        urlParams.set("assessmentId", candidate?.assessmentId);
        urlParams.set("candidateId", candidate?._id);
        navigate(
          {
            pathname: location.pathname,
            search: urlParams.toString(),
          },
          { replace: true }
        );
        await setCandidate(candidate);
        // await setasssessmentConformPage(true);
        await setAsssessmentStart(true);
      }
      if (res?.data?.candidateDetail) setIsEmail(true);
    } catch (err) {
      logger.error(err);
    }
  }, []);

  return (
    <Card>
      <BlockStack gap={400}>
        <Text variant="headingLg">Interested?</Text>
        <CommonForm
          formRef={formRef}
          initialValues={candidateDetailsPublicInitialValues}
          formFields={getCandidateDetailFields(jobData, stage.publicUrlField)}
          isSave={false}
          noValueChanged={false}
          onSubmit={handleSubmit}
          title="Add New Candidate"
        />

        <InlineStack>
          <Button variant="primary" size="large" onClick={submitForm}>
            Apply now
          </Button>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};
