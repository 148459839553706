import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Collapsible,
  EmptyState,
  Icon,
  InlineStack,
  LegacyCard,
  Text,
} from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { useAuthenticatedFetch } from "@/Api/Axios";
import * as Images from "@/Assets/Index";
import { UserContext } from "@/Context/UserContext";

export const Notifications = ({ jobData, setNotificationsNotRead }) => {
  const fetch = useAuthenticatedFetch();
  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [buttonDesable, setButtonDesable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getNotifications();
  }, [page]);

  const getNotifications = useCallback(async () => {
    jobData["isNotificationRead"] = true;
    const res = await fetch.post(`/getNotifications?page=${page}&pageSize=${pageSize}`, jobData);
    setButtonDesable(res?.data?.count < page * pageSize);
    setNotifications(res?.data?.rows);
    setNotificationsNotRead(res?.data?.unreadCount);
  }, [page, pageSize]);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const handleRowClick = (row, state) => {
    const idParam = state ? `?id=${row.stageId}` : "";
    navigate(`/candidate/${row.candidateId}${idParam}`);
  };

  const handleNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const handlePrevPage = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  const infoCard = useCallback(
    (value, index) => {
      return (
        <Card key={index}>
          <Box padding="100">
            <BlockStack gap={100}>
              <InlineStack gap={300} blockAlign="center">
                {value?.massageDetails?.assessmentFinish ? (
                  <Text variant="bodyLg" as="p">
                    Candidate{" "}
                    <b className="notification" onClick={() => handleRowClick(value, false)}>
                      {value.massageDetails.candidateName}
                    </b>{" "}
                    has successfully completed the assessment for the{" "}
                    <b className="notification" onClick={() => handleRowClick(value, true)}>
                      {value.massageDetails.interviewRound}
                    </b>{" "}
                    interview round.
                  </Text>
                ) : (
                  <Text variant="bodyLg" as="p">
                    Candidate{" "}
                    <b className="notification" onClick={() => handleRowClick(value, false)}>
                      {value.massageDetails.candidateName}
                    </b>{" "}
                    has been successfully created through a public link for the{" "}
                    <b className="notification" onClick={() => handleRowClick(value, true)}>
                      {value.massageDetails.interviewRound}
                    </b>{" "}
                    interview round.
                  </Text>
                )}
                <Text as="p" fontWeight="medium" tone="subdued">
                  {formatTimeAgo(value?.created)}
                </Text>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
      );
    },
    [notifications]
  );

  return (
    <>
      {notifications && notifications.length > 0 ? (
        <>
          <BlockStack gap={400}>
            {notifications.map((e, index) => infoCard(e, index))}
            <InlineStack align="center">
              <ButtonGroup variant="segmented">
                <Button onClick={() => handlePrevPage()} disabled={page == 1}>
                  <Icon source={ChevronLeftMinor} />
                </Button>
                <Button onClick={() => handleNextPage()} disabled={buttonDesable}>
                  <Icon source={ChevronRightMinor} />
                </Button>
              </ButtonGroup>
            </InlineStack>
          </BlockStack>
        </>
      ) : (
        <LegacyCard>
          <EmptyState heading="No Notifications list Found" image={Images.EmptyReuslt}></EmptyState>
        </LegacyCard>
      )}
    </>
  );
};
