import React, { useState } from "react";
import { InlineStack, Text } from "@shopify/polaris";
import { VideoDisplay } from "../../../../Components/Common/VideoPlayer";

export default function InterviewQuestion({ currentQuestion, bannerShow }) {
  if (bannerShow) {
    return <></>;
  }
  if (!bannerShow) {
    return (
      <div
        className={`${
          currentQuestion?.crtQuation?.questionType == "video" &&
          currentQuestion?.crtQuation?.answerType != "number"
            ? "quation-input"
            : "other-quation"
        }`}
      >
        {currentQuestion?.crtQuation?.questionType == "video" ? (
          <div className="quations">
            <div className="quations-list" style={{ marginLeft: "10px", marginRight: "50px" }}>
              {`Question ${currentQuestion?.currentQuationIndex + 1}:`}
              <VideoDisplay
                url={`https://${process.env.VULTR_BUCKETNAME}.ewr1.vultrobjects.com/${currentQuestion?.crtQuation?.questionVideo?.assetUrl}`}
                duration={Math.floor(currentQuestion?.crtQuation?.questionVideo?.duration)}
                settings={{
                  width: "400px",
                  height: "320px",
                }}
              />
            </div>
          </div>
        ) : (
          <InlineStack align="center">
            <Text variant="headingMd">{`Question ${currentQuestion?.currentQuationIndex + 1}: ${
              currentQuestion?.crtQuation?.question
            }`}</Text>
          </InlineStack>
        )}
      </div>
    );
  }
}
