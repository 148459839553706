import { Box, Icon, InlineStack, List, Scrollable, Text, Tooltip } from "@shopify/polaris";
import { QuestionMarkMajor } from "@shopify/polaris-icons";

const tooltipContent = (level) => (
  <Box padding="100">
    <Text variant="headingSm" as="h6">
      {level === "low"
        ? "Low-Level Assessment"
        : level === "medium"
        ? "Medium-Level Assessment"
        : "Hard-Level Assessment"}
    </Text>
    <Scrollable style={{ height: "200px" }}>
      {level === "low" && (
        <InlineStack gap={100}>
          <Text>
            "This is a straightforward assessment to get you started! It's designed to quickly gauge your core
            skills—complete it and you'll move forward in the process."
          </Text>
          <Text>
            "This quick assessment is meant to evaluate your basic knowledge. Don’t worry, it’s simple and won’t
            take long. Finish it to move ahead!"
          </Text>
          <Text>
            "The assessment is easy and focuses on basic screening questions. Completing this will bring you one
            step closer to the interview."
          </Text>
          <Text>
            "Take a few minutes to complete this basic test, and you're well on your way to securing your next
            role."
          </Text>
          <Text>
            "It’s a short and easy test—just a screening to get to know your abilities. Finish it, and we can take
            the next steps together!"
          </Text>
        </InlineStack>
      )}
      {level === "medium" && (
        <InlineStack gap={100}>
          <Text>
            "This assessment is a great way to showcase your skills. It’s moderately challenging, but passing it
            will definitely set you apart from other candidates!"
          </Text>
          <Text>
            "The questions in this assessment are more in-depth, but if you complete it, your chances of moving
            forward increase significantly!"
          </Text>
          <Text>
            "The test is AI-generated and designed to challenge you, but it's totally manageable. Complete it to
            demonstrate your capabilities and stand out!"
          </Text>
          <Text>
            "The questions are designed to test your skill level more closely, but finishing this will put you in a
            strong position for the next phase."
          </Text>
          <Text>
            "This medium-level assessment helps us assess your qualifications in more depth. Take your time and
            give it your best shot to boost your chances of getting hired."
          </Text>
        </InlineStack>
      )}
      {level === "hard" && (
        <InlineStack gap={100}>
          <Text>
            "This is a challenging test, with only 10% of candidates completing it. But if you finish, you'll boost
            your chances of moving forward by 80%!"
          </Text>
          <Text>
            "You’ve reached one of the toughest parts of the process. Finishing this assessment shows exceptional
            ability and greatly improves your chances."
          </Text>
          <Text>
            "The level of difficulty here is high, but those who complete it often secure interviews faster. Show
            us your skills by taking it to the end!"
          </Text>
          <Text>
            "This assessment is tough, but finishing it gives you a huge edge over other candidates—only the most
            committed make it through!"
          </Text>
          <Text>
            "It’s challenging, but that’s exactly why completing this assessment will set you apart from the
            competition. Take it on, and you’ll significantly increase your chances!"
          </Text>
        </InlineStack>
      )}
    </Scrollable>
  </Box>
);
export const stageFields = (level, questionTypeLevel) => {
  let fields = [
    {
      id: "stageTitle",
      name: "stageTitle",
      label: "Interview Round Title",
      type: "text",
      errormsg: "Interview Round title is required",
      validated: true,
    },
    {
      nested: "group",
      section: false,
      groupSize: 4,
      subfields: [
        {
          radioId: "noQuestion",
          id: "noQuestion",
          name: "stagePreset",
          label: "No Questions",
          type: "radio",
        },
        {
          radioId: "normalScreening",
          id: "normalScreening",
          name: "stagePreset",
          label: "Custom Questions",
          type: "radio",
        },
        {
          radioId: "aiScreening",
          id: "aiScreening",
          name: "stagePreset",
          label: "AI Generated Questions",
          type: "radio",
        },
        {
          radioId: "bothScreening",
          id: "bothScreening",
          name: "stagePreset",
          label: "Custom + AI Generated Questions",
          type: "radio",
        },
      ],
    },
  ];
  if (!questionTypeLevel) {
    fields.push(
      {
        label: "Assessment Level",
        nested: "group",
        section: true,
        groupSize: 3,
        subfields: [
          {
            radioId: "low",
            id: "low",
            name: "assessmentLevel",
            label: "Low",
            type: "radio",
          },
          {
            radioId: "medium",
            id: "medium",
            name: "assessmentLevel",
            label: "Medium",
            type: "radio",
          },
          {
            radioId: "hard",
            id: "hard",
            name: "assessmentLevel",
            label: "Hard",
            type: "radio",
          },
          {
            id: "specialInstruction",
            name: "specialInstruction",
            label: "Inspiration Message",
            validated: true,
            multiline: true,
            autoComplete: "off",
            type: "helpTextSelector",
            errormsg: "Inspiration Message is required",
            helpText:
              "Add here inspiration message so candidate will finish assessment. click on help icon for more suggestions",
            tooltip: (
              <Tooltip content={tooltipContent(level)}>
                <Icon source={QuestionMarkMajor} />
              </Tooltip>
            ),
          },
        ],
      },
      {
        label: "Would you like to set up the assessment in a single form or as a multi-step process?",
        nested: "group",
        section: true,
        groupSize: 2,
        subfields: [
          {
            radioId: "single",
            id: "single",
            name: "assessmentType",
            label: "Single Assessment Form",
            type: "radio",
          },
          {
            radioId: "multipleStep",
            id: "multipleStep",
            name: "assessmentType",
            label: "Multi-Step Assessment Form",
            type: "radio",
          },
        ],
      }
    );
  }
  return fields;
};

export const stageInitialValues = {
  stagePreset: "normalScreening",
  assessmentLevel: "low",
  stageTitle: "",
  specialInstruction: "",
  assessmentType: "multipleStep",
};

const questionTime = [
  {
    id: "questionTime",
    name: "questionTime",
    label: "Think time for Question (In seconds)",
    validated: false,
    type: "number",
    min: 0,
    helpText: "If no value is entered, the time limit will be 60 seconds.",
  },
  {
    id: "answerTime",
    name: "answerTime",
    label: "Answer time (In seconds)",
    validated: false,
    type: "number",
    min: 0,
    helpText: "If no value is entered, the time limit will be 300 seconds(5 Minutes).",
  },
];

export const normalScreeningFields = (isSingleForm) => {
  let fields = [
    {
      id: "questionList",
      name: "questionList",
      label: "Question List",
      nested: "array",
      allowDrag: true,
      showDeleteToolTip: true,
      showCollapsible: true,
      CollapsibleLabel:
        "`Question- ${index + 1} :  ${values?.questionList?.[index]?.questionType === 'text' ?  `${values?.questionList?.[index]?.question || ''}` : values?.questionList?.[index]?.questionType === 'video' ? `video` : ''}`",
      minimum: 0,
      groupSize: 1,
      groupLable: "Question",
      subfields: [
        {
          id: "questionType",
          name: "questionType",
          label: "Question type",
          validated: true,
          type: "select",
          errormsg: "Question type is required",
          options: [
            {
              label: "Select type",
              value: "",
            },
            {
              label: "Text",
              value: "text",
            },
            {
              label: "Video",
              value: "video",
            },
          ],
        },
        {
          id: "question",
          name: "question",
          label: "Question",
          type: "text",
          errormsg: "Question is required",
          validated: true,
          dependOn: {
            name: "questionType",
            isArray: true,
            value: "text",
            type: "hidden",
          },
        },
        {
          id: "questionVideo",
          name: "questionVideo",
          label: "Question",
          type: "video",
          autoUpload: true,
          isStopRecording: true,
          reRecordable: true,
          isPublic: false,
          errormsg: "Question video is required",
          dependOn: {
            name: "questionType",
            isArray: true,
            value: "video",
            type: "hidden",
          },
          validated: true,
          //Video Input settings : https://www.npmjs.com/package/react-player#props - check link for other props
          // pass settings of video player
          settings: {
            width: "300px",
            height: "225px",
          },
        },
        {
          id: "answerType",
          name: "answerType",
          label: "Answer type",
          validated: true,
          type: "select",
          errormsg: "Answer type is required",
          options: [
            {
              label: "Select type",
              value: "",
            },
            {
              label: "Text",
              value: "text",
            },
            {
              label: "Video",
              value: "video",
            },
            {
              label: "Number",
              value: "number",
            },
            {
              label: "Check Box",
              value: "checkbox",
            },
            {
              label: "Radio Button",
              value: "radio",
            },
          ],
        },
        {
          id: "checkBoxOption",
          name: "checkBoxOption",
          label: "Checkbox Option",
          type: "array",
          minimum: 2,
          groupSize: 3,
          errormsg: "Checkbox Option is required",
          validated: true,
          arrayType: "text",
          initialValue: ["", ""],
          dependOn: {
            name: "answerType",
            value: "checkbox",
            isArray: true,
            type: "hidden",
          },
        },
        {
          id: "radioBtnOption",
          name: "radioBtnOption",
          label: "Radio Option",
          type: "array",
          minimum: 2,
          groupSize: 3,
          errormsg: "Radio Option is required",
          validated: true,
          arrayType: "text",
          initialValue: ["", ""],
          dependOn: {
            name: "answerType",
            value: "radio",
            isArray: true,
            type: "hidden",
          },
        },
      ],
    },
  ];
  if (!isSingleForm) {
    fields[0].subfields.push(
      {
        nested: "group",
        groupSize: 2,
        section: false,
        subfields: questionTime,
      },
      {
        id: "aiEvaluation",
        name: "aiEvaluation",
        label: "Evaluate answer by AI",
        type: "checkbox",
        dependOn: {
          name: ["answerType"],
          isArray: true,
          value: ["number", "checkbox", "radio"],
          type: "disabled",
        },
        dependOn: {
          name: "questionType",
          isArray: true,
          value: ["video"],
          type: "disabled",
        },
      }
    );
  } else {
    fields[0].subfields.push({
      id: "aiEvaluation",
      name: "aiEvaluation",
      label: "Evaluate answer by AI",
      type: "checkbox",
      dependOn: {
        name: "answerType",
        isArray: true,
        value: ["number", "checkbox", "radio"],
        type: "disabled",
      },
      dependOn: {
        name: "questionType",
        isArray: true,
        value: ["video"],
        type: "disabled",
      },
    });
  }
  return fields;
};
export const normalScreeningInitialValues = {
  questionList: [
    {
      file: [],
      questionType: "text",
      answerTime: "",
      questionTime: "",
      question: "",
      questionVideo: "",
      answerType: "",
      checkBoxOption: ["", ""],
      radioBtnOption: ["", ""],
      aiEvaluation: true,
    },
  ],
};
export const getAiScreeningFields = (position = false, isSingleForm) => {
  let fields = [
    {
      id: "totalQuestions",
      name: "totalQuestions",
      label: "Select the number of questions to generate using AI",
      validated: true,
      type: "rangeSlider",
      max: 30,
      min: 5,
    },
  ];
  if (!isSingleForm) {
    fields.push({
      nested: "group",
      groupSize: 2,
      section: false,
      subfields: questionTime,
    });
  }
  if (position) {
    fields.push({
      id: "aiQuestionPosition",
      name: "aiQuestionPosition",
      type: "select",
      label: "AI Question Position",
      options: [
        {
          label: "Select Position",
          value: "",
        },
        {
          label: "Before Normal Screening Questions",
          value: "before",
        },
        {
          label: "After Normal Screening Questions",
          value: "after",
        },
      ],
    });
  }

  return fields;
};

export const aiScreeningInitialValues = {
  totalQuestions: 10,
  answerTime: "",
  questionTime: "",
  aiQuestionPosition: "before",
};

export const getFields = (data, status) => {
  let fields = [
    {
      id: "answerVideo",
      name: "answerVideo",
      label: "Question",
      type: "video",
      errormsg: "Question video is required",
      autoUpload: false,
      reRecordable: false,
      isCountdown: true,
      validated: status === "single" ? true : false,
      countdown: Number(data?.questionTime),
      isStopRecording: status === "single" ? true : false,
      banner: status === "single" ? false : true,
      //Video Input settings : https://www.npmjs.com/package/react-player - check link for other props
      // pass settings of video player
      settings: {
        // muted: true,
        // playing: true,
        // loop: true,
        width: "400px",
        height: "350px",
      },
      isSubmitVisible: true,
    },
  ];
  let initialValues = {
    answerVideo: "",
    textAns: "",
  };

  if (data?.answerType === "text" || data?.answerType === "number") {
    fields = [
      {
        id: "textAns",
        name: "textAns",
        label: " ",
        validated: true,
        type: data?.answerType || "text",
        placeholder: "Provide a brief Answer",
        errormsg: "Answer is required",
        multiline: data?.answerType === "text" ? 5 : false,
        requiredIndicator: false,
      },
    ];
    initialValues = {
      textAns: "",
    };
  }

  if (data?.answerType === "checkbox" || data?.answerType === "radio") {
    let options =
      data.answerType === "checkbox"
        ? data.checkBoxOption.map((option) => ({ label: option, value: option }))
        : data.radioBtnOption.map((option) => ({ label: option, value: option }));
    fields = [
      {
        id: "textAns",
        name: "textAns",
        label: " ",
        validated: true,
        min: 1,
        // max: 3,
        type: "choiceList",
        errormsg: "Answer is required",
        allowMultiple: data?.answerType === "checkbox" ? true : false,
        choices: options,
      },
    ];
    initialValues = {
      textAns: [],
    };
  }

  return {
    fields: fields,
    initialValues: initialValues,
  };
};

export const testScreeningFields = [
  {
    id: "testVideo",
    name: "testVideo",
    label: "Question",
    type: "video",
    height: 400,
    width: 400,
    autoUpload: false,
    isStopRecording: true,
    isTestScreen: true,
    //Video Input settings : https://www.npmjs.com/package/react-player - check link for other props
    // pass settings of video player
    settings: {
      width: "400px",
      height: "350px",
    },
  },
];

export const testScreeningInitialValues = {
  testVideo: "",
};

export const testQuestionFields = [
  {
    id: "testVideo",
    name: "testVideo",
    label: "Question",
    type: "video",
    errormsg: "answer is required",
    height: 400,
    width: 400,
    autoUpload: true,
    isCountdown: false,
    isStopRecording: true,
    reRecordable: true,
    validated: true,
    isPublic: true,
    videoUrl: true,
    takeScreenshot: true,
    //Video Input settings : https://www.npmjs.com/package/react-player - check link for other props
    // pass settings of video player
    settings: {
      width: "400px",
      height: "350px",
    },
  },
];

export const testQuestionValues = {
  testVideo: "",
};
