import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlockStack, Button, Card, InlineStack, Page, Text } from "@shopify/polaris";

export const EmailAlreadyExixstPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const startAssessment = async () => {
    const shareParam = urlParams.get("assessmentId");

    const testPath = `/assessment/${shareParam}`;
    navigate(
      {
        pathname: testPath,
      },
      { replace: true }
    );
  };

  return (
    <Page>
      <Card>
        <BlockStack align="center" gap="400">
          <div className="assessment-complate" style={{ padding: "0px 180px" }}>
            <Text variant="headingLg" alignment="center">
              You have already applied for this job.
            </Text>
          </div>
          <div className="assessment-complate" style={{ padding: "0px 180px" }}>
            <InlineStack gap={200}>
              <Text alignment="center">
                Do you want to complete the assessment? If so, please check your email for the link, or we can
                provide the link below.
              </Text>
            </InlineStack>
          </div>
          <InlineStack gap="200" align="center">
            <Button variant="primary" size="large" onClick={startAssessment}>
              Assessemnt Link
            </Button>
          </InlineStack>
        </BlockStack>
      </Card>
    </Page>
  );
};
