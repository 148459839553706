const filterOperatorChoices = [
  { label: "", value: "" },
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than and Equals to", value: "$gte" },
  { label: "Equals to", value: "$eq" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than and Equals to", value: "$lte" },
];
// productCount

const compareFormField = (field, type, label) => {
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: field,
        nested: "object",
        groupSize: 1,
        section: false,
        subfields: [
          {
            id: "operators",
            name: "operators",
            label: "Operators",
            type: "select",
            options: filterOperatorChoices,
          },
          {
            id: type,
            name: type,
            label: type,
            type: type,
            min: 0,
          },
        ],
      },
    ],
    filterType: type,
  };
};

const makeNormalField = (field, type = "text", label) => {
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: field,
        type: type,
      },
    ],
  };
};

const makeChoiceField = (field, nestedKey, label) => {
  const key = nestedKey ? { nestedKey } : {};
  return {
    key: field,
    label: label || field,
    formFields: [
      {
        id: field,
        name: field,
        label: field,
        type: "choiceList",
        choices: [
          { label: "true", value: true },
          { label: "false", value: false },
        ],
      },
    ],
    initialValue: [],
    filterType: "array",
    ...key,
  };
};

export const getFilterField = () => {
  return [
    makeNormalField("country_code"),
    makeNormalField("country_name"),
    makeNormalField("currency"),
    makeNormalField("country_code"),
    makeNormalField("plan_display_name"),
    makeNormalField("plan_name"),
    makeNormalField("recurringPlanType"),
    compareFormField("productCount", "number"),
    compareFormField("stepCount", "number"),
    compareFormField("trial_days", "number"),
    compareFormField("lastLogin", "date", "LastLogin"),
    compareFormField("trial_start", "date", "Trial_start"),
    compareFormField("created_at", "date", "Shop_age"),
    compareFormField("created", "date", "Installed"),
    makeChoiceField("isOnBoardingDone"),
    makeChoiceField("reviewRequest", "reviewRequest.isReviewPosted"),
  ];
};

export const excludeFields = [
  "_id",
  "shopUrl",
  "__v",
  "accessToken",
  "storeName",
  "storeId",
  "email",
  "phone",
  "shop_owner",
  "role",
  "domain",
  "customer_email",
  "googleAuthTokens",
  "created",
  // "created_at",
  "updated",
  "scope",
  "privateUserMetafield",
  "privateMetafield",
  "metafield",
];
