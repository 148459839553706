import React, { useEffect, useState } from "react";
import { BlockStack, Box, Button, Card, Icon, InlineGrid, InlineStack, List, Text } from "@shopify/polaris";
import { ContentMinor } from "@shopify/polaris-icons";
import parse from "html-react-parser";

export const Instruction = (props) => {
  const { stageData, stage, setStep } = props;
  const [time, setTime] = useState();
  const [totalQuestion, setTotalQuestion] = useState(0);

  useEffect(() => {
    answerFormChange();
  }, [stage, time]);

  const answerFormChange = () => {
    const videoAnswerTypeCount = stage?.questionList?.filter(
      (question) => question?.answerType === "video"
    ).length;
    let totalQuestion =
      videoAnswerTypeCount || stage?.totalQuestions ? videoAnswerTypeCount + stage?.totalQuestions : 0;
    let stagetotalQuestion =
      videoAnswerTypeCount || stageData?.totalQuestions ? videoAnswerTypeCount + stageData?.totalQuestions : 0;
    setTotalQuestion(stageData ? stagetotalQuestion : totalQuestion);
    let Questions =
      (stageData && stageData?.totalQuestions ? stageData?.totalQuestions : 0) +
        (stageData && stageData?.questionList ? stageData?.questionList?.length : 0) ||
      (stage && stage?.totalQuestions ? stage?.totalQuestions : 0) +
        (stage && stage?.questionList ? stage?.questionList?.length : 0);
    const minTime = Math.ceil(Questions / 2) * 2;
    const maxTime = Math.ceil(Questions / 3) * 5;
    setTime(`${minTime} - ${maxTime}`);
  };
  const handleNext = () => {
    setStep(3);
  };
  return (
    <BlockStack gap="400">
      <InlineGrid gap="200" columns="1">
        <Box align="start">
          <InlineStack align="space-between">
            <Text variant="headingXl">Interview Instructions</Text>
            <div className="applyNow" style={{ display: "none" }}>
              <Button variant="primary" size="large" onClick={handleNext}>
                Next
              </Button>
            </div>
          </InlineStack>
        </Box>
      </InlineGrid>
      {(stage?.specialInstruction || stageData?.specialInstruction) && (
        <div className="specialInstruction">
          {parse(stage?.specialInstruction ? stage?.specialInstruction : stageData?.specialInstruction)}
        </div>
      )}

      <Card background="bg-fill-disabled">
        <InlineStack>
          <InlineStack gap="200">
            <Icon source={ContentMinor} tone="base" />
            <Text variant="bodyLg">
              {(stageData && stageData?.totalQuestions ? stageData?.totalQuestions : 0) +
                (stageData && stageData?.questionList ? stageData?.questionList?.length : 0) ||
                (stage && stage?.totalQuestions ? stage?.totalQuestions : 0) +
                  (stage && stage?.questionList ? stage?.questionList?.length : 0)}{" "}
              Questions to be answered
            </Text>
          </InlineStack>
        </InlineStack>
      </Card>

      <BlockStack gap="200" align="start">
        <Text variant="headingLg"> Important Interview Guidelines</Text>
        <List type="bullet">
          {/* <List.Item>
            <Text variant="headingMd" as="h6">
              This entire Ai test will take around {time} min time.
            </Text>
          </List.Item> */}
          {totalQuestion > 0 && (
            <List.Item>
              <Text variant="headingMd" as="h6">
                There are {totalQuestion} questions you need to answer with a video recording. Ensure you are in a
                comfortable environment with 5-10 minutes of uninterrupted time before starting the test.
              </Text>
            </List.Item>
          )}
          <List.Item>
            Answering each question is<b> mandatory</b>
          </List.Item>
          <List.Item>
            <b>Switching tabs</b> or opening new ones is not permitted.
          </List.Item>
          <List.Item>
            If the test requires webcam usage, please <b>grant the necessary permissions</b> in your browser.
          </List.Item>
          <List.Item>
            Ensure you are using the latest browser version; we <b>recommend Google Chrome.</b>
          </List.Item>
          <List.Item>
            Take the time to practice and refine your responses if you feel unprepared.<b>Be fully ready</b> before
            starting the recordings.
          </List.Item>
        </List>
      </BlockStack>

      <BlockStack gap="200" align="start">
        <Text variant="headingLg"> Interview Question Details: </Text>
        <List type="bullet">
          <List.Item>
            <InlineStack gap="200" align="start">
              <Text as="p" tone="subdued">
                Video Type answers :
              </Text>
              {stage ? (
                <Text as="p" fontWeight="medium">
                  {stage?.questionList && stage?.totalQuestions
                    ? stage.questionList.filter((question) => question.answerType === "video").length +
                      stage.totalQuestions
                    : stage?.questionList
                    ? stage.questionList.filter((question) => question.answerType === "video").length
                    : stage?.totalQuestions
                    ? stage.totalQuestions
                    : 0}
                </Text>
              ) : (
                <Text as="p" fontWeight="medium">
                  {stageData?.questionList && stageData?.totalQuestions
                    ? stageData.questionList.filter((question) => question.answerType === "video").length +
                      stageData.totalQuestions
                    : stageData?.questionList
                    ? stageData.questionList.filter((question) => question.answerType === "video").length
                    : stageData?.totalQuestions
                    ? stageData.totalQuestions
                    : 0}
                </Text>
              )}
            </InlineStack>
          </List.Item>

          {(stage?.questionList?.filter((question) => question?.answerType === "text").length > 0 ||
            stageData?.questionList?.filter((question) => question?.answerType === "text").length > 0) && (
            <List.Item>
              <InlineStack gap="200" align="start">
                <Text as="p" tone="subdued">
                  Text Type answers:
                </Text>
                {stage ? (
                  <Text as="p" fontWeight="medium">
                    {stage?.questionList?.filter((question) => question?.answerType === "text").length}{" "}
                  </Text>
                ) : (
                  <Text as="p" fontWeight="medium">
                    {stageData?.questionList?.filter((question) => question?.answerType === "text").length}{" "}
                  </Text>
                )}
              </InlineStack>
            </List.Item>
          )}
          {(stage?.questionList?.filter((question) => question?.answerType === "number").length > 0 ||
            stageData?.questionList?.filter((question) => question?.answerType === "number").length > 0) && (
            <List.Item>
              <InlineStack gap="200" align="start">
                <Text as="p" tone="subdued">
                  Number Type answers:
                </Text>
                {stage ? (
                  <Text as="p" fontWeight="medium">
                    {stage?.questionList?.filter((question) => question?.answerType === "number").length}{" "}
                  </Text>
                ) : (
                  <Text as="p" fontWeight="medium">
                    {stageData?.questionList?.filter((question) => question?.answerType === "number").length}{" "}
                  </Text>
                )}
              </InlineStack>
            </List.Item>
          )}
          {(stage?.questionList?.filter((question) => question?.answerType === "checkbox").length > 0 ||
            stageData?.questionList?.filter((question) => question?.answerType === "checkbox").length > 0) && (
            <List.Item>
              <InlineStack gap="200" align="start">
                <Text as="p" tone="subdued">
                  Checkbox Type answers:
                </Text>
                {stage ? (
                  <Text as="p" fontWeight="medium">
                    {stage?.questionList?.filter((question) => question?.answerType === "checkbox").length}{" "}
                  </Text>
                ) : (
                  <Text as="p" fontWeight="medium">
                    {stageData?.questionList?.filter((question) => question?.answerType === "checkbox").length}{" "}
                  </Text>
                )}
              </InlineStack>
            </List.Item>
          )}
          {(stage?.questionList?.filter((question) => question?.answerType === "radio").length > 0 ||
            stageData?.questionList?.filter((question) => question?.answerType === "radio").length > 0) && (
            <List.Item>
              <InlineStack gap="200" align="start">
                <Text as="p" tone="subdued">
                  Radio type answers:
                </Text>
                {stage ? (
                  <Text as="p" fontWeight="medium">
                    {stage?.questionList?.filter((question) => question?.answerType === "radio").length}{" "}
                  </Text>
                ) : (
                  <Text as="p" fontWeight="medium">
                    {stageData?.questionList?.filter((question) => question?.answerType === "radio").length}{" "}
                  </Text>
                )}
              </InlineStack>
            </List.Item>
          )}
        </List>
      </BlockStack>
    </BlockStack>
  );
};
