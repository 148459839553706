import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  IndexTable,
  InlineStack,
  List,
  Modal,
  Text,
} from "@shopify/polaris";
import { ClipboardMinor, DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { get, set } from "mongoose";
import { useAuthenticatedFetch } from "@/Api/Axios";
import {
  aiScreeningInitialValues,
  getAiScreeningFields,
  normalScreeningFields,
  normalScreeningInitialValues,
  stageFields,
  stageInitialValues,
} from "@/Assets/Mocks/StageDetails.mock";
import { getSaveTemplateFields, templateInitialValues } from "@/Assets/Mocks/Template.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import {
  candidatePublicUrlFields,
  candidatePublicUrlInitialValues,
} from "../../../Assets/Mocks/StagePublicUrl.mock";
import { AccessPermission, PageAccessPermission } from "../../../Components/Common/AccessPermission";
import { AddInterview } from "./AddInterview";
import { PublicStage } from "./PublicStage";
import { Template } from "./templates/Template";

export function Stage(props) {
  const [saveTemplateFields, setSaveTemplateFields] = useState([]);
  const fetch = useAuthenticatedFetch();
  const [isChooseTemplateModal, setIsChooseTemplateModal] = useState(false);
  const [stageFormValues, setStageFormValues] = useState(stageInitialValues);
  const [normalScreeningFormFields, setNormalScreeningFormFields] = useState(normalScreeningFields);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});
  const [actionType, setActionType] = useState("");
  const [screeningFormFields, setScreeningFormFields] = useState(normalScreeningFormFields);
  const [screeningFormValues, setScreeningFormValues] = useState(normalScreeningInitialValues);
  const [template, setTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showTemplateSaveButton, setShowTemplateSaveButton] = useState(true);
  const childRef = useRef();
  const [isPublic, setIsPublicOpen] = useState(false);
  const [publicUrlFormValues, SetPublicUrlFormValues] = useState(candidatePublicUrlInitialValues);
  const { showToast } = useContext(ToastContext);
  const [isPublicEdit, setIsPublicEdit] = useState(false);
  const { id } = useParams();
  const [jobData, setJobData] = useState();
  const [backUpStageValue, setBackUpStageValue] = useState({});
  const [saveAllTemplateFields, setSaveAllTemplateFields] = useState([]);
  const [publicUrl, setPublicUrl] = useState(false);
  const [stageData, setstageData] = useState();
  const [showAddInterview, setShowAddInterview] = useState(false);

  const navigate = useNavigate();

  const handleAddStageButtonClick = () => {
    setIsChooseTemplateModal(true);
    setBackUpStageValue({});
  };
  const handleAddStage = useCallback(() => {
    setActionType("add");
    setstageData(null);
    setStageFormValues(stageInitialValues);
    setScreeningFormFields(normalScreeningFields);
    setScreeningFormValues(normalScreeningInitialValues);
    setShowAddInterview(true);
  }, [actionType]);

  const getTemplates = useCallback(async () => {
    const response = await fetch.get("/stageTemplates");
    const res = await fetch.get(`/job/${id}`);
    setJobData(res.data);
    createRowsData();
    if (response?.data) {
      setTemplates(response.data);
      setSaveTemplateFields(getSaveTemplateFields(response.data));
      setSaveAllTemplateFields(getSaveTemplateFields(response.data));
    }
  }, [jobData, id]);

  useEffect(() => {
    getTemplates();
  }, []);

  const [pagination, setParentPagination] = useState(null);

  const copyToClipboard = async (row) => {
    const url = `${process.env.APP_URL + "/stage/share/" + row._id}`;
    await navigator.clipboard.writeText(url);
    showToast("Link copied to clipboard.");
  };

  const handlePublicUrl = async (row) => {
    if (row?.publicUrlField?.resume) {
      setPublicUrl(true);
    }
    SetPublicUrlFormValues({
      ...candidatePublicUrlInitialValues,
      ...row,
    });
    setIsPublicEdit(row?.publicUrlField ? true : false);
    setIsPublicOpen(true);
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        row["type"] = "update";
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell>
              {row?.stageDetails?.stageTitle}
              {/* <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                {row?.stageDetails?.stageTitle}
              </Link> */}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {row?.stageDetails?.stagePreset === "normalScreening" && "Normal Screening"}
              {row?.stageDetails?.stagePreset === "aiScreening" && "Ai Screening"}
              {row?.stageDetails?.stagePreset === "bothScreening" && "Ai + Normal Screening"}
              {row?.stageDetails?.stagePreset === "noQuestion" && "No Questions"}
            </IndexTable.Cell>
            {/* <IndexTable.Cell>{row?.stageDetails?.questionList?.length}</IndexTable.Cell> */}
            <IndexTable.Cell>{formatTimeAgo(row?.created)}</IndexTable.Cell>
            <IndexTable.Cell>{row?.userdetail[0]?.firstName}</IndexTable.Cell>

            <IndexTable.Cell>
              {row?.isPublic || row?.publicUrl ? (
                <InlineStack gap={200}>
                  <Badge tone="success">Public</Badge>
                  {jobData && jobData?.isActive === true && (
                    <Button
                      plain
                      monochrome
                      icon={EditMinor}
                      onClick={() => {
                        handlePublicUrl(row);
                      }}
                    />
                  )}
                  <a
                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    onClick={() => copyToClipboard(row)}
                  >
                    <Icon source={ClipboardMinor} />
                  </a>
                </InlineStack>
              ) : (
                <Button
                  variant="primary"
                  disabled={!jobData?.isActive}
                  onClick={() => {
                    handlePublicUrl(row);
                  }}
                >
                  Public
                </Button>
              )}
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  <AccessPermission access={["manageJobs", "manageCandidate"]}>
                    {jobData?.isActive && (
                      <Button
                        plain
                        monochrome
                        icon={EditMinor}
                        onClick={() => {
                          handleEditStage(row);
                          setShowTemplateSaveButton(true);
                        }}
                      />
                    )}
                    <Button
                      plain
                      monochrome
                      icon={DeleteMinor}
                      onClick={() => {
                        handleDelete(row);
                      }}
                    />
                  </AccessPermission>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [childRef, pagination, jobData]
  );

  const handleEditStage = useCallback(
    async (row) => {
      setActionType("update");
      setActionType(row.type);
      if (row?._id) setSelectedStage(row);
      const data = {
        ...stageFormValues,
        ...screeningFormValues,
        ...row?.stageDetails?.stageDetails,
        ...row?.stageDetails,
      };
      setstageData(data);
      setShowAddInterview(true);
    },
    [stageFormValues, screeningFormValues, actionType]
  );

  const handleDelete = async (row) => {
    setStageToDelete(row);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteCanceled = () => {
    setStageToDelete(null);
    setIsDeleteModalOpen(false);
  };
  const handleDeleteConfirmed = useCallback(async () => {
    try {
      if (stageToDelete) {
        await fetch.delete(`/stage/${stageToDelete._id}`);
        childRef.current.fetchData();
        showToast("Interview Round Delete successfully");
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [stageToDelete, childRef]);

  return (
    <PageAccessPermission access={["manageJobs", "viewJobs"]}>
      <BlockStack gap="200">
        <InlineStack align="flex-end" blockAlign="center">
          <AccessPermission access={["manageJobs"]}>
            {/* <Button size="large" onClick={handleAddStage}> */}
            <Button size="large" disabled={!jobData?.isActive} onClick={handleAddStageButtonClick}>
              Add Interview Round
            </Button>
          </AccessPermission>
        </InlineStack>
        {jobData && (
          <CommonTable
            title="Job Interview Interview Rounds"
            selectable={false}
            url={`/stages/${props.jobId}`}
            rowsData={createRowsData}
            headings={[
              { title: "No" },
              { title: "Title" },
              { title: "Type" },
              { title: "Created At" },
              { title: "Created By" },
              { title: "Public" },
              // { title: "Questions" },
              { title: "Action" },
            ]}
            ref={childRef}
            columnContentTypes={["string", "Action"]}
            isAdd={false}
            setParentPagination={setParentPagination}
          />
        )}
      </BlockStack>

      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteCanceled}
        title="Confirm Delete"
        primaryAction={{
          content: "Delete",
          onAction: handleDeleteConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleDeleteCanceled,
          },
        ]}
      >
        <Modal.Section>
          <Text>Are you sure you want to delete this Interview Round?</Text>
        </Modal.Section>
      </Modal>

      <Template
        isChooseTemplateModal={isChooseTemplateModal}
        handleModalClose={() => {
          setIsChooseTemplateModal(false), setTemplate(false);
        }}
        handleEditStage={handleEditStage}
        handleAddStage={handleAddStage}
        templates={templates}
        setShowTemplateSaveButton={setShowTemplateSaveButton}
        setActionType={setActionType}
      />
      {showAddInterview && (
        <AddInterview
          actionType={actionType}
          setBackUpStageValue={setBackUpStageValue}
          backUpStageValue={backUpStageValue}
          showTemplateSaveButton={showTemplateSaveButton}
          stageData={stageData}
          selectedStage={selectedStage}
          setShowAddInterview={setShowAddInterview}
          status="Round"
          jobData={jobData}
          childRef={childRef}
        ></AddInterview>
      )}
      {isPublic && (
        <PublicStage
          SetPublicUrlFormValues={SetPublicUrlFormValues}
          publicUrlFormValues={publicUrlFormValues}
          isPublicEdit={isPublicEdit}
          publicUrl={publicUrl}
          setPublicUrl={setPublicUrl}
          childRef={childRef}
          setIsPublicOpen={setIsPublicOpen}
        ></PublicStage>
      )}
    </PageAccessPermission>
  );
}
