import React, { useEffect, useState } from "react";
import { Badge, BlockStack, Button, Icon, InlineStack, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import parse from "html-react-parser";

export const JobPreview = (props) => {
  const { jobData, organization, assessmentPreviews, step, setStep } = props;
  const [imgUrl, setImgUrl] = useState();

  useEffect(() => {
    if (assessmentPreviews?.logo?.length == 0) {
      setImgUrl();
    } else if (assessmentPreviews?.logo?.url) {
      const url = assessmentPreviews?.logo?.url;
      const path = new URL(url).pathname;
      let imageUrl = `${process.env.APP_URL + path}`;
      setImgUrl(imageUrl);
    } else if (organization?.assessmentPreview?.logo?.url) {
      const url = organization?.assessmentPreview?.logo?.url;
      const path = new URL(url).pathname;
      let imageUrl = `${process.env.APP_URL + path}`;
      setImgUrl(imageUrl);
    }
  }, [assessmentPreviews, imgUrl, organization]);

  const handleNext = () => {
    setStep(step + 1);
  };

  return (
    <BlockStack gap="200">
      {jobData?.jobDescription && (
        <BlockStack gap="400">
          <InlineStack align="space-between">
            <InlineStack gap={100} blockAlign="center">
              <Icon color="subdued" source={ClipboardMinor} tone="base" />
              <Text variant="headingMd" color="subdued">
                Job Description
              </Text>
            </InlineStack>
            <div className="applyNow" style={{ display: "none" }}>
              <Button variant="primary" size="large" onClick={handleNext}>
                Next
              </Button>
            </div>
          </InlineStack>
          <BlockStack inlineAlign="start">
            <InlineStack align="start" gap="100">
              {jobData?.keySkills?.split(",").map((skill, index) => (
                <Badge key={index}>{skill ? skill : "React Js"}</Badge>
              ))}
            </InlineStack>
            {jobData && jobData.jobDescription && (
              <div className="tinyPreview jobDescription">{parse(jobData.jobDescription)}</div>
            )}
          </BlockStack>
        </BlockStack>
      )}
    </BlockStack>
  );
};
