import React, { useCallback, useRef, useState } from "react";
import { BlockStack, Box, Button, InlineGrid, InlineStack, List, Text } from "@shopify/polaris";
import axios from "axios";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { testQuestionFields, testQuestionValues } from "../../../../../Assets/Mocks/StageDetails.mock";

export const TestQuestion = ({ setStep, step, assessmentData, setImage }) => {
  const formRef = useRef();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const fetch = useAuthenticatedFetch();
  const [imageUrl, setImageUrl] = useState();

  const submitForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const handleFormChange = useCallback(
    (e) => {
      setImageUrl(e?.testVideo?.snapshot);
      setIsButtonDisabled(e?.testVideo?.videoURL ? false : true);
    },
    [isButtonDisabled]
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (assessmentData) {
        const response = await axios.get("https://pro.ip-api.com/json?key=9ICbsngAdWQxmmq");
        const userAgent = navigator.userAgent;
        const isMobile = /Mobi|Android/i.test(userAgent) ? "Mobile" : "Desktop";
        const os = /Win/i.test(userAgent)
          ? "Windows"
          : /Mac/i.test(userAgent)
          ? "MacOS"
          : /Android/i.test(userAgent)
          ? "Android"
          : /iPhone|iPad/i.test(userAgent)
          ? "iOS"
          : "Unknown OS";
        const browser = /Firefox/i.test(userAgent)
          ? "Firefox"
          : /OPR|Opera/i.test(userAgent)
          ? "Opera"
          : /Chrome/i.test(userAgent)
          ? "Chrome"
          : /Safari/i.test(userAgent)
          ? "Safari"
          : /Edge/i.test(userAgent)
          ? "Edge"
          : "Unknown Browser";

        response.data["device"] = isMobile;
        response.data["osSystem"] = os;
        response.data["browser"] = browser;

        let res = await fetch.post(
          "/assessment",
          {
            assessment_id: assessmentData?._id,
            imageUrl: imageUrl,
            isVultrVideo: true,
            ipAddressData: response?.data,
          },
          false
        );
      } else {
        setImage(imageUrl);
      }
      setStep(step + 1);
    },
    [imageUrl]
  );

  return (
    <BlockStack gap={200}>
      <InlineGrid gap="200" columns="1">
        <Box align="start">
          <Text variant="headingXl">Assessment Quality Check</Text>
        </Box>
      </InlineGrid>
      <List>
        <List.Item>
          <p>
            <strong>Quality Check For Video:</strong> Ensure clarity and smooth playback of video.
          </p>
        </List.Item>

        <List.Item>
          <p>
            <strong>Check Lighting:</strong> Sit in a well lit room without glare or shadows.
          </p>
        </List.Item>
        <div className="QualitycheckPoint">
          <List.Item>
            <p>
              <strong>Check Microphone:</strong> Listen to the playback of your voice and ensure it is clearly
              audible before beginning.
            </p>
          </List.Item>
          <List.Item>
            <p>
              <strong>Inspect Background:</strong> Maintain a distraction-free environment and ensure cleanliness
              in your surroundings during the assessment.
            </p>
          </List.Item>
          <List.Item>
            <p>
              <strong>Review and Rerecord:</strong> Review the video until the mentioned points are fulfilled. If
              unsatisfied, re-record the video and recheck.
            </p>
          </List.Item>
        </div>
      </List>

      <InlineStack align="center">
        <BlockStack gap={400} inlineAlign="center">
          <Text variant="headingLg" as="p" fontWeight="semibold">
            Test Question - Provide a brief introduction about yourself in 3 minutes.
          </Text>
          <InlineStack align="center">
            <CommonForm
              formRef={formRef}
              initialValues={testQuestionValues}
              formFields={testQuestionFields}
              isSave={false}
              noValueChanged={false}
              title="Add New Candidate"
              onSubmit={handleSubmit}
              onFormChange={handleFormChange}
            />
          </InlineStack>
        </BlockStack>
      </InlineStack>

      <InlineStack align="end">
        <Button variant="primary" disabled={isButtonDisabled} size="large" onClick={submitForm}>
          Next
        </Button>
      </InlineStack>
    </BlockStack>
  );
};
