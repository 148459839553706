import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, EmptyState, InlineStack, LegacyCard } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import * as Images from "@/Assets/Index";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { candidateDetailsInitialValues, getCandidateDetailFields } from "../../../Assets/Mocks/Candidate.mock.jsx";
import { sourcingFormFields, sourcingInitialValues } from "../../../Assets/Mocks/ResourcingMock";
import { CandidateDetails } from "../Jobs/CandidateDetails.jsx";

export function Resourcing() {
  const childRef = useRef();
  const [isResourcing, setIsResourcing] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [showCandidateDetails, setShowCandidateDetails] = useState(false);
  const formRef = useRef();
  const [loading, setLoading] = useState(true);
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const fetch = useAuthenticatedFetch();

  const handleButtonClick = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    console.log(e);
    if (e?.email) {
      await fetch.post("/candidate", {
        ...e,
      });
      setIsCandidateModelOpen(false);
      let data = {
        keySkills: e?.skills ? e?.skills : "",
        location: e?.currentLocation ? e?.currentLocation : "",
        workExperience: e?.totalExperience ? e?.totalExperience : "",
      };
      setSearchValue(data);
    } else {
      setSearchValue(e);
    }
    setLoading(true);
    setIsResourcing(true);
  }, []);

  useEffect(() => {
    if (isResourcing && !showCandidateDetails) {
      setShowCandidateDetails(true); // Set to true only on the first run
    }
  }, [isResourcing]);

  const handleCandidateClick = () => {
    setIsCandidateModelOpen(true);
  };

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
  };

  const saveCandidate = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  return (
    <>
      <CommonForm
        onSubmit={handleSubmit}
        formRef={formRef}
        initialValues={sourcingInitialValues}
        formFields={sourcingFormFields}
        isSave={false}
        noValueChanged={false}
      />
      <InlineStack align="end" gap={200}>
        <Button onClick={handleCandidateClick}>Add Candidate </Button>
        <Button variant="primary" onClick={handleButtonClick}>
          Search
        </Button>
      </InlineStack>

      {showCandidateDetails ? (
        <>
          <CandidateDetails
            sourcing={true}
            searchValue={searchValue}
            childRef={childRef}
            loading={loading}
            setLoading={setLoading}
          />
        </>
      ) : (
        <LegacyCard>
          <EmptyState heading="No Candidate list Found" image={Images.EmptyReuslt}></EmptyState>
        </LegacyCard>
      )}
      <BannerModal
        size={"large"}
        open={isCandidateModelOpen}
        onClose={handleModalClose}
        title={"Add New Candidate"}
        primaryAction={{
          content: "Submit",
          onAction: saveCandidate,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalClose,
          },
        ]}
        children={
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={candidateDetailsInitialValues}
            formFields={getCandidateDetailFields()}
            title="Add New Candidate"
            isSave={false}
          />
        }
      />
    </>
  );
}
