import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Card, InlineGrid, InlineStack, Modal, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import {
  candidatePublicUrlFields,
  candidatePublicUrlInitialValues,
} from "../../../Assets/Mocks/StagePublicUrl.mock";

export const PublicStage = ({
  SetPublicUrlFormValues,
  publicUrlFormValues,
  isPublicEdit,
  publicUrl,
  setPublicUrl,
  childRef,
  setIsPublicOpen,
}) => {
  const [isPublicModel, setIsPublicModelOpen] = useState(true);
  const publicUrlFormRef = useRef();
  const fetch = useAuthenticatedFetch();
  const { showToast } = useContext(ToastContext);

  const handlePublicUrlCancle = () => {
    setIsPublicModelOpen(false);
    setPublicUrl(false);
    setIsPublicOpen(false);
  };

  const generatePublicLink = async (e) => {
    const Url = `${process.env.APP_URL + "/stage/share/" + e._id}`;
    e["isPublic"] = true;
    e["publicUrl"] = Url;
    await fetch.post("/stage", { data: e });
    childRef.current.fetchData();
    showToast("Sucessfully Generate Public Url");
    setIsPublicModelOpen(false);
    SetPublicUrlFormValues({ ...candidatePublicUrlInitialValues });
    setIsPublicOpen(false);
  };

  const generatePublcUrl = useCallback(async () => {
    if (publicUrlFormRef.current) {
      publicUrlFormRef.current.handleSubmit();
      let publicUrlData = publicUrlFormRef.current.values;
      await generatePublicLink(publicUrlData);
    }
  }, [publicUrlFormRef]);

  const onpublicUrlField = (value) => {
    if (value?.publicUrlField?.resume) {
      setPublicUrl(true);
    } else {
      setPublicUrl(false);
    }
  };

  return (
    <>
      <Modal
        open={isPublicModel}
        onClose={handlePublicUrlCancle}
        title="Create Public Url"
        primaryAction={{
          content: isPublicEdit ? "Update" : "Save",
          onAction: generatePublcUrl,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handlePublicUrlCancle,
          },
        ]}
      >
        <Box padding="300">
          <div className="publicUrl">
            <CommonForm
              formRef={publicUrlFormRef}
              initialValues={publicUrlFormValues}
              onSubmit={generatePublicLink}
              formFields={candidatePublicUrlFields(publicUrl)}
              onFormChange={onpublicUrlField}
              isSave={false}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};
