import React, { useCallback, useEffect, useState } from "react";
import { ActionList, Button, Popover } from "@shopify/polaris";
import { getValueFromNestedObject } from "@/Utils/Index";

export default function CustomSelector(props) {
  const {
    form: { values, setFieldValue },
    field: { name, options },
  } = props;
  const value = getValueFromNestedObject(values, name);
  const [inputValue, setInputValue] = useState(value);
  const [inputLabel, setInputLabel] = useState(options?.find((e) => e.value === value)?.label);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const setItemValue = (e) => {
    setInputValue(e?.value);
    setInputLabel(e?.label);
    toggleActive();
  };

  const activator = (
    <Button onClick={toggleActive} fullWidth disclosure="select" textAlign="left">
      {inputLabel}
    </Button>
  );

  const makeItem = options?.map((e) => ({
    content: e.label,
    onAction: () => setItemValue(e),
    active: inputValue === e?.value,
  }));

  useEffect(() => {
    setFieldValue(name, inputValue);
  }, [inputValue]);

  return (
    <Popover active={active} activator={activator} autofocusTarget="first-node" onClose={toggleActive} fullWidth>
      <ActionList actionRole="menuitem" items={makeItem} />
    </Popover>
  );
}
