import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, InlineStack, Page, Text } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { CommonHeader } from "../Common/CommonHeader";
import { AssessmentTest } from "../InviteAssessment/AssessmentTest";
import { ExpiredPage } from "./ExpiredPage";
import { SingleFormTest } from "./SingleFormTest";

export const EmailAssessment = () => {
  const fetch = useAuthenticatedFetch();
  const [jobData, setJobData] = useState();
  const [isStarted, setIsStarted] = useState(false);
  const { id } = useParams();
  const [assessmentId, setAssessmentId] = useState();
  const [publicAssessmentData, setAssessmentData] = useState();
  const [organizationData, setOrganizationData] = useState();
  const [stage, setStage] = useState();
  const [assessmentPage, setisAssessmentPage] = useState(false);
  const [assessmentExpired, setaAssessmentExpired] = useState(false);

  useEffect(() => {
    fetchData();
  }, [assessmentExpired]);

  const fetchData = useCallback(async () => {
    setAssessmentId(id);
    const res = await fetch.get(`/getAssessment/${id}`);
    if (res?.data) {
      setAssessmentData(res?.data);
      if (res?.data?.answers?.length > 0) {
        setIsStarted(true);
      }
      const job = await fetch.get(`/getJob/${res?.data?.jobId}`);
      setJobData(job?.data);
      const stageData = await fetch.get(`/getStage/${res?.data?.stageId}?isPublic=true`);
      setStage(stageData?.data);
      const organization = await fetch.get(`/organization/${job?.data?.orgId}`);
      setOrganizationData(organization?.data);
    } else {
      setisAssessmentPage(true);
    }
  }, [jobData, isStarted, publicAssessmentData]);

  if (assessmentPage) {
    return (
      <Page>
        <Card>
          <InlineStack align="center">
            <Text variant="headingMd">The assessment could not be found. </Text>
          </InlineStack>
        </Card>
      </Page>
    );
  }

  return (
    <>
      {publicAssessmentData && publicAssessmentData?.isExpire ? (
        <div style={{ minHeight: "520px" }}>
          <CommonHeader
            jobData={jobData}
            setIsStarted={setIsStarted}
            isStarted={isStarted}
            organizationData={organizationData}
          />
          <ExpiredPage publicAssessmentData={publicAssessmentData} setaAssessmentExpired={setaAssessmentExpired} />
        </div>
      ) : (
        publicAssessmentData && (
          <div style={{ minHeight: "520px" }}>
            <CommonHeader
              jobData={jobData}
              setIsStarted={setIsStarted}
              isStarted={isStarted}
              organizationData={organizationData}
            />
            {stage?.stageDetails?.assessmentType === "single" ? (
              <SingleFormTest
                isPublic={true}
                assessmentId={assessmentId}
                publicAssessmentData={publicAssessmentData}
                stage={stage}
              />
            ) : (
              <AssessmentTest
                isPublic={true}
                assessmentId={assessmentId}
                publicAssessmentData={publicAssessmentData}
                stage={stage}
              />
            )}
          </div>
        )
      )}
    </>
  );
};
