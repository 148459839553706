import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Button,
  IndexTable,
  InlineStack,
  Link,
  Modal,
  Page,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor, MergeMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import LZString from "lz-string";
import { useAuthenticatedFetch } from "@/Api/Axios";
import {
  JobDescriptionByAiFields,
  JobDescriptionByAiInitialValues,
  JobDescriptionFields,
  JobDescriptionInitialValues,
  JobDetailsFields,
  JobDetailsInitialValues,
  MergeJobFields,
  MergeJobInitialValues,
  getFilterField,
} from "@/Assets/Mocks/JobDetails.mock";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ToastContext } from "@/Context/ToastContext";
import { AccessPermission, PageAccessPermission } from "../../../Components/Common/AccessPermission";
import StarJob from "../../../Components/Common/StarJob";
import { UserContext } from "../../../Context/UserContext";
import { setLocalStorageFilter } from "../../../Utils/AppUtils";
import { CommonReminder } from "../Settings/CommonReminder";

const getField = () => [
  {
    step: 1,
    name: "Enable our app",
  },
  {
    step: 2,
    name: "Features",
  },
  {
    step: 3,
    name: "Pricing",
  },
];
export const Jobs = () => {
  const { userProfileData, updateProfileInfo } = useContext(UserContext);
  const fetch = useAuthenticatedFetch();
  const [isJobModelOpen, setIsJobModelOpen] = useState(false);
  const [jobFormValues, setJobFormValues] = useState(JobDetailsInitialValues);
  const [actionType, setActionType] = useState("");
  const navigate = useNavigate();
  const formRef = useRef();
  const childRef = useRef();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [pagination, setParentPagination] = useState(null);
  const { showToast } = useContext(ToastContext);
  const [options, setOptions] = useState(null);
  const [isMergeJobModelOpen, setIsMergeJobModelOpen] = useState(false);
  const [id, setId] = useState();
  const [jobs, setJobs] = useState(null);
  const [isDeactiveJobModel, setIsDeactiveJobModelOpen] = useState(false);
  const [jobStatus, setIsJobStatus] = useState();
  const [parentSelectedTab, setParentSelectedTab] = useState(0);

  const tabs = [
    {
      id: "active",
      content: "Active Jobs",
      searchQuery: { key: "isActive", value: true, tabQuery: true },
      hideFilter: [""],
    },
    {
      id: "deactive",
      content: "Inactive Jobs",
      searchQuery: { key: "isActive", value: false, tabQuery: true },
      hideFilter: [""],
    },
  ];
  const [stepCount, setStepCount] = useState(1);
  const field = getField();
  const [isJobDescription, setJobDescription] = useState();

  const saveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);
  useEffect(() => {}, [parentSelectedTab]);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        if (e.jobdescription && stepCount == 1) {
          setJobDescription(e.jobdescription);
          e.jobdescription === "normal" ? setStepCount(stepCount + 2) : setStepCount(stepCount + 1);
        } else if (stepCount == 2) {
          e["aiGenerated"] = true;
          let job = await fetch.post("/job", e);
          if (job?.data) {
            setStepCount(stepCount + 1);
            setJobFormValues({ ...JobDetailsInitialValues, ...job.data });
          }
        } else if (e.jobs) {
          e["jobId"] = id;
          await fetch.post("/mergeJobs", e);
          showToast("Merge Job successfully");
          setLocalStorageFilter(true, parentSelectedTab, childRef, "job");
          setIsMergeJobModelOpen(false);
        } else {
          const { jobDescription, candidatedetail, ...rest } = e;
          const compressedJobDescription = LZString.compress(e.jobDescription);

          await fetch.post("/job", {
            ...rest,
            jobDescription: compressedJobDescription,
          });
          actionType === "add" ? showToast("Job Created successfully") : showToast("Job Updated successfully");
          setJobFormValues(JobDetailsInitialValues);
          await cutCredit();
          setIsJobModelOpen(false);
          setStepCount(1);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [actionType, childRef, id, stepCount, parentSelectedTab]
  );

  const cutCredit = useCallback(async () => {
    if (
      (jobStatus === "Reactive" || actionType === "add") &&
      userProfileData?.pricingPlan?.featureLimitation?.jobCredit
    ) {
      userProfileData.pricingPlan.featureLimitation.jobCredit -= 1;
      const updatedPricingPlan = {
        ...userProfileData.pricingPlan,
      };

      const pricingPlanRes = await fetch.put("/putPlan", updatedPricingPlan);
      const updatedUserProfileData = {
        ...userProfileData,
        pricingPlan: pricingPlanRes.data,
      };

      updateProfileInfo(updatedUserProfileData);
    }
    setLocalStorageFilter(true, parentSelectedTab, childRef, "job");
  }, [parentSelectedTab, childRef, actionType, jobStatus]);

  const addCredit = useCallback(async () => {
    userProfileData.pricingPlan.featureLimitation.jobCredit += 1;
    const updatedPricingPlan = {
      ...userProfileData.pricingPlan,
    };

    const pricingPlanRes = await fetch.put("/putPlan", updatedPricingPlan);
    const updatedUserProfileData = {
      ...userProfileData,
      pricingPlan: pricingPlanRes.data,
    };

    updateProfileInfo(updatedUserProfileData);
    setLocalStorageFilter(true, parentSelectedTab, childRef, "job");
  }, [parentSelectedTab, childRef]);

  const handleButtonClick = () => {
    setActionType("add");
    setIsJobModelOpen(true);
  };

  const handleModalClose = () => {
    setIsJobModelOpen(false);
    setStepCount(1);
    setJobFormValues({ ...JobDetailsInitialValues });
  };

  useEffect(() => {
    getJobAccess();
  }, []);

  const getJobAccess = useCallback(async () => {
    if (userProfileData?.pricingPlan?.isTrial == false) {
      navigate(`/pricing`);
    }
    const res = await fetch.get(`/users`, false);
    const options = res.data.rows.flatMap(({ organizations, _id, email, firstName }) =>
      organizations.some(({ permission }) => permission.some(({ name }) => name === "limitedAccess"))
        ? [{ value: _id, label: firstName ? firstName : email }]
        : []
    );
    setOptions(options);
  }, []);

  const handleEdit = (row) => {
    setActionType("update");
    setStepCount(3);
    setJobFormValues({ ...JobDetailsInitialValues, ...row });
    setIsJobModelOpen(true);
  };

  const handleDeactiveJobs = (row, status) => {
    setIsJobStatus(status);
    setJobFormValues(row);
    setIsDeactiveJobModelOpen(true);
  };

  const handleDelete = useCallback((row) => {
    setJobToDelete(row);
    setIsDeleteModalOpen(true);
  }, []);

  const handleDeleteConfirmed = useCallback(async () => {
    if (jobToDelete) {
      await fetch.delete(`/job/${jobToDelete._id}`);
      setLocalStorageFilter(true, parentSelectedTab, childRef, "job");
      setJobToDelete(null);
      setIsDeleteModalOpen(false);
      showToast("Job Deleted successfully");
    }
  }, [parentSelectedTab, childRef, jobToDelete]);

  const handleDeactiveConfirmed = useCallback(async () => {
    jobFormValues["isActive"] = jobStatus == "Deactive" ? false : true;
    if (jobStatus == "Reactive") {
      jobFormValues["deactive"] = true;
    }
    await fetch.post("/job", jobFormValues);
    setLocalStorageFilter(true, parentSelectedTab, childRef, "job");
    if (userProfileData?.pricingPlan?.featureLimitation?.jobCredit) {
      await (jobStatus === "Reactive" ? cutCredit() : addCredit());
    }
    setIsDeactiveJobModelOpen(false);
    showToast("Job Reactiveed");
  }, [jobStatus, parentSelectedTab, childRef]);

  const handleDeleteCanceled = () => {
    setJobToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeactiveJobCanceled = () => {
    setIsDeactiveJobModelOpen(false);
  };

  const handleRowClick = (row) => {
    navigate(`/jobs/${row._id}`);
  };

  const handleMergeJobs = async (row) => {
    setId(row._id);
    const res = await fetch.post(`/alljobs`);
    const jobs = res.data.rows.flatMap(({ _id, jobTitle }) => [{ value: _id, label: jobTitle }]);
    setJobs(jobs);
    setIsMergeJobModelOpen(true);
  };
  const handleMergeModalClose = () => {
    setIsMergeJobModelOpen(false);
  };

  const mergeJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const nextStep = useCallback(async () => {
    formRef.current.handleSubmit();
  }, [stepCount]);

  const filterFormFields = useMemo(() => getFilterField(), []);

  const createRowsData = useCallback(
    (rows) => {
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row id={row._id} key={row._id} position={index}>
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell key={row._id + 1}>
              <BlockStack gap="200">
                <InlineStack gap={100}>
                  <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                    <InlineStack gap="200">
                      <div style={{ maxWidth: "300px", whiteSpace: "normal", overflowWrap: "break-word" }}>
                        <Text variant="headingMd" as="h6">
                          {row?.jobTitle}
                        </Text>
                      </div>
                    </InlineStack>
                  </Link>
                  <StarJob jobData={row} width={"21px"}></StarJob>
                </InlineStack>
                {row?.jobLocation && (
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Location :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.jobLocation ? row?.jobLocation : "-"}
                    </Text>
                  </InlineStack>
                )}
                {row?.employmentType && (
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Employment Type :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.employmentType ? row?.employmentType : "-"}
                    </Text>
                  </InlineStack>
                )}
              </BlockStack>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Work Mode :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.workMode ? row?.workMode : "-"}
                  </Text>
                </InlineStack>
                {row?.workExperience && (
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Experience :
                    </Text>
                    <InlineStack gap={100}>
                      <Text as="p" fontWeight="medium">
                        {row?.workExperience}
                      </Text>
                      {row?.MaxWorkExperience && (
                        <Text as="p" fontWeight="medium">
                          - {row?.MaxWorkExperience}
                        </Text>
                      )}
                    </InlineStack>
                  </InlineStack>
                )}
              </BlockStack>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created By :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.userdetail[0]?.firstName}
                  </Text>
                </InlineStack>
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created At :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {formatTimeAgo(row?.created)}
                  </Text>
                </InlineStack>
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Candidates :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row?.candidatedetail?.length}
                  </Text>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                {row?.jobAccess && row?.jobAccess.map((item, index) => <Text key={index}>{item.label}</Text>)}
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack>
                <InlineStack gap="100">
                  <AccessPermission access={["manageJobs"]}>
                    <BlockStack>
                      <InlineStack gap="100">
                        {row?.isActive && (
                          <Button
                            icon={EditMinor}
                            onClick={() => {
                              handleEdit(row);
                            }}
                          />
                        )}
                        <Button
                          icon={DeleteMinor}
                          onClick={() => {
                            handleDelete(row);
                          }}
                        />
                        {row?.isActive ? (
                          <Tooltip content="Merge Jobs">
                            <Button
                              icon={MergeMinor}
                              onClick={() => {
                                handleMergeJobs(row);
                              }}
                            ></Button>
                          </Tooltip>
                        ) : (
                          <Button
                            size="large"
                            disabled={userProfileData?.pricingPlan?.featureLimitation?.jobCredit == 0}
                            onClick={() => {
                              handleDeactiveJobs(row, "Reactive");
                            }}
                          >
                            Reactive Job
                          </Button>
                        )}
                      </InlineStack>

                      {/* {row?.isActive && (
                        <Button
                          size="large"
                          onClick={() => {
                            handleDeactiveJobs(row, "Deactive");
                          }}
                        >
                          Deactive Job
                        </Button>
                      )} */}
                    </BlockStack>
                  </AccessPermission>
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [childRef, handleRowClick, handleEdit, handleDelete]
  );

  return (
    <PageAccessPermission access={["manageJobs", "viewJobs"]}>
      <Page
        fullWidth={true}
        title={"Jobs"}
        secondaryActions={
          <AccessPermission access={["manageJobs"]}>
            <InlineStack align="end" gap="400">
              {(userProfileData?.pricingPlan?.featureLimitation?.jobCredit ||
                userProfileData?.pricingPlan?.featureLimitation?.jobCredit == 0) && (
                <Badge
                  fullWidth
                  tone={userProfileData?.pricingPlan?.featureLimitation?.jobCredit == 0 ? "critical" : "success"}
                >
                  <Text variant="headingSm" align="center">
                    {`Job Credits`} : {userProfileData?.pricingPlan?.featureLimitation?.jobCredit}
                  </Text>
                </Badge>
              )}
              <Button
                size="large"
                disabled={userProfileData?.pricingPlan?.featureLimitation?.jobCredit == 0}
                onClick={handleButtonClick}
              >
                Add Job
              </Button>
            </InlineStack>
          </AccessPermission>
        }
      >
        <BlockStack gap="200">
          {userProfileData && (
            <CommonTable
              resourceName={{
                singular: "Job",
                plural: "Jobs",
              }}
              title="Jobs"
              queryPlaceholder="Search Job by (Job Title, Job Location, Created By)"
              url={`/jobs`}
              isFilterVisible
              rowsData={createRowsData}
              setParentPagination={setParentPagination}
              setParentSelectedTab={setParentSelectedTab}
              selectable={false}
              headings={[
                { title: "No" },
                { title: "Job Details" },
                { title: "Work Details" },
                { title: "Created By" },
                { title: "Access By" },
                { title: "Action" },
              ]}
              searchKey={["jobTitle", "jobLocation", "userdetail.firstName"]}
              pinnedFilter={["isStar"]}
              ref={childRef}
              columnContentTypes={["text", "string", "string", "string", "string", "Action"]}
              isAdd={false}
              showTab={true}
              tabOption={tabs}
              parentFilters={[{ ...tabs[0]?.searchQuery }]}
              filterFormFields={filterFormFields}
              localStorageKey={`job`}
            />
          )}
        </BlockStack>
        <BannerModal
          open={isJobModelOpen}
          onClose={handleModalClose}
          title={actionType === "update" ? "Edit Job" : "Add a new Job"}
          primaryAction={{
            content: actionType === "update" ? "Update" : stepCount < field.length ? "Next" : "Finish",
            onAction: nextStep,
          }}
          children={
            <>
              {stepCount === 1 && (
                <CommonForm
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  initialValues={JobDescriptionInitialValues}
                  formFields={JobDescriptionFields}
                  isSave={false}
                  noValueChanged={false}
                />
              )}
              {stepCount === 2 && (
                <CommonForm
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  initialValues={JobDescriptionByAiInitialValues}
                  formFields={JobDescriptionByAiFields}
                  isSave={false}
                  noValueChanged={false}
                />
              )}
              {stepCount === 3 && (
                <>
                  <CommonForm
                    onSubmit={handleSubmit}
                    formRef={formRef}
                    initialValues={jobFormValues}
                    formFields={JobDetailsFields(options)}
                    isSave={false}
                    noValueChanged={false}
                  />
                </>
              )}
            </>
          }
          size={stepCount === 3 ? "large" : ""}
        />
        <Modal
          open={isDeleteModalOpen}
          onClose={handleDeleteCanceled}
          title="Confirm Delete"
          primaryAction={{
            content: "Delete",
            onAction: handleDeleteConfirmed,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleDeleteCanceled,
            },
          ]}
        >
          <Modal.Section>
            <Text>Are you sure you want to delete this job?</Text>
          </Modal.Section>
        </Modal>
        <BannerModal
          open={isMergeJobModelOpen}
          onClose={handleMergeModalClose}
          title={"Merge Job"}
          primaryAction={{
            content: "Merge Jobs",
            onAction: mergeJob,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleMergeModalClose,
            },
          ]}
          children={
            <CommonForm
              onSubmit={handleSubmit}
              formRef={formRef}
              initialValues={MergeJobInitialValues}
              formFields={MergeJobFields(jobs)}
              isSave={false}
            />
          }
        />
        <Modal
          open={isDeactiveJobModel}
          onClose={handleDeactiveJobCanceled}
          title="Reactive Job"
          primaryAction={{
            content: jobStatus,
            onAction: handleDeactiveConfirmed,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleDeactiveJobCanceled,
            },
          ]}
        >
          <Modal.Section>
            <Text>Are you sure you want to {jobStatus} this job?</Text>
          </Modal.Section>
        </Modal>
      </Page>
    </PageAccessPermission>
  );
};
